import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { previousBidtableData } from "../../data";

const PreviousBid = () => {
    const navigate = useNavigate();
    const [filterBy, setFilterBy] = useState("");
    const [filterType, setFilterType] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/one-cent")} className="text-[#828282] cursor-pointer">1 - Cent Bidding</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Previous Bids</p>
                </div>

                {/* main content */}
                <div className="flex items-center justify-between mt-5">
                    <p className="text-[20px] font-bold">
                        Previous Bids
                    </p>
                    <div className="flex items-center gap-x-2">
                        <div className="relative">
                            <select
                                required
                                value={filterBy}
                                onChange={(e) => setFilterBy(e.target.value)}
                                className="w-[18rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Filter Ads by Date">Filter Ads by Date</option>
                                <option value="Filter Ads by Week">Filter Ads by Week</option>
                                <option value="Filter Ads by Month">Filter Ads by Month</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                        <div className="relative">
                            <select
                                required
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                                className="w-[18rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Filter Ads by Type">Filter Ads by Type</option>
                                <option value="Filter Ads by Type 2">Filter Ads by Type 2</option>
                                <option value="Filter Ads by Type 3">Filter Ads by Type 3</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="mt-10">
                    <p className="text-[18px] font-bold underline underline-offset-[5px]">
                        Your Recent Bids
                    </p>
                    <table className="border-collapse border border-[#969696] mt-5 w-full text-center">
                        <thead>
                            <tr className="text-[#ffffff]">
                                <th className="p-3 bg-[#ababab] border border-[#828282]">Advert Name</th>
                                <th className="bg-[#ababab] border border-[#828282]">Date</th>
                                <th className="bg-[#ababab] border border-[#828282]">Ad Type</th>
                                <th className="bg-[#ababab] border border-[#828282]">Ad Reserve ($)</th>
                                <th className="bg-[#ababab] border border-[#828282]">Max Bid Price ($)</th>
                                <th className="bg-[#ababab] border border-[#828282]">Max PPI ($)</th>
                                <th className="bg-[#ababab] border border-[#828282]">Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {previousBidtableData.map((data, index) => (
                                <tr key={index}>
                                    <td className="p-3 border border-[#828282]">{data.advertName}</td>
                                    <td className="border border-[#828282]">{data.date}</td>
                                    <td className="border border-[#828282]">{data.adType}</td>
                                    <td className="border border-[#828282]">{data.adReserve}</td>
                                    <td className="border border-[#828282]">{data.maxBidPrice}</td>
                                    <td className="border border-[#828282]">{data.maxPPI}</td>
                                    <td className="border border-[#828282]">{data.invoice}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PreviousBid