import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { IoClose, IoFilterSharp } from "react-icons/io5";
import { MdKeyboardArrowRight, MdFilterList } from "react-icons/md";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Financials = () => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(true);
    const [showModifyAxis, setShowModifyAxis] = useState(false);
    const [showDateModify, setShowDateModify] = useState(false);

    const handleModifyAxis = () => {
        setShowModifyAxis(!showModifyAxis);
        setShowDateModify(false);
    }

    const handleDateModify = () => {
        setShowDateModify(!showDateModify);
        setShowModifyAxis(false);
    }

    const data = [
        { name: 'Jan', uv: 4520, pv: 2600, amt: 2350 },
        { name: 'Feb', uv: 3120, pv: 1450, amt: 2235 },
        { name: 'Mar', uv: 2150, pv: 10500, amt: 2400 },
        { name: 'Apr', uv: 2980, pv: 4100, amt: 2100 },
        { name: 'May', uv: 1950, pv: 4900, amt: 2260 },
        { name: 'Jun', uv: 2500, pv: 3900, amt: 2650 },
        { name: 'Jul', uv: 3550, pv: 5400, amt: 2150 },
        { name: 'Aug', uv: 3320, pv: 4500, amt: 2250 },
        { name: 'Sep', uv: 3600, pv: 4700, amt: 2350 },
        { name: 'Oct', uv: 3750, pv: 9900, amt: 2200 },
        { name: 'Nov', uv: 3890, pv: 4300, amt: 2150 },
        { name: 'Dec', uv: 4020, pv: 7200, amt: 2400 },
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Financials</p>
                </div>

                {/* main content */}
                {showPopup &&
                    <div className="flex items-center justify-center">
                        <div className="w-[30%] border border-[#000000] px-5 pt-2 pb-8 rounded mt-[5rem]">
                            <div className="flex justify-end">
                                <IoClose
                                    onClick={() => setShowPopup(false)}
                                    className="text-[24px] text-[#000000] hover:text-[#f35252] cursor-pointer"
                                />
                            </div>
                            <p className="text-center text-[18px] font-bold mt-5">
                                Welcome to the KAS Financials Page!
                            </p>
                            <p className="text-center text-[16px] mt-5">
                                As you create adverts and bid, your bills,
                                invoices and financial data will populate here.
                            </p>
                            <p className="text-center text-[16px] mt-5">
                                Here, you can view your financial data such as how
                                much you have spent per Advert, per day, week, month or year.
                            </p>
                            <p className="text-center text-[16px] mt-5">
                                You can access graphical forms of the data and download your invoices!
                            </p>
                        </div>
                    </div>
                }

                {!showPopup &&
                    <div className="mt-5">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-x-5">
                                <p className="text-[14px] border border-[#d9d9d9] text-[#000000] py-2 px-4 rounded">
                                    Business : Anton’s Cookies
                                </p>
                                <p className="text-[14px] bg-[#d9d9d9] text-[#000000] py-2 px-4 rounded">
                                    Tier : Tier 3
                                </p>
                            </div>
                            <p className="text-[14px] bg-[#d9d9d9] text-[#000000] py-2 px-4 rounded">
                                Date Created : July 1st, 2025
                            </p>
                        </div>
                        <p className="text-[14px] bg-[#000000] text-[#0DD315] py-2 px-4 rounded w-fit mt-5">
                            Date Created : July 1st, 2025
                        </p>
                        <div className="grid grid-cols-12 mt-5">
                            <div className="col-span-9">
                                <p className="text-[16px] font-bold underline underline-offset-[5px]">
                                    Financial Summary
                                </p>
                                <div className="flex items-center gap-x-3 text-[14px] mt-5">
                                    <p className="text-[14px] font-bold">Monthly Expenditures</p>
                                    <MdFilterList className="text-[18px] text-[#6c6767]" />
                                </div>
                                <div className="flex items-center gap-x-3 text-[14px] mt-5">
                                    <p className="text-[14px]">July 24 :</p>
                                    <p className="text-[14px] font-bold">$ 2000</p>
                                </div>
                                <div className="flex items-center gap-x-3 text-[14px] mt-2">
                                    <p className="text-[14px]">August 24 :</p>
                                    <p className="text-[14px] font-bold">$ 567</p>
                                </div>
                                <div className="flex items-center gap-x-3 text-[14px] mt-2 mb-10">
                                    <p className="text-[14px]">September 24 :</p>
                                    <p className="text-[14px] font-bold">$ 2,359</p>
                                </div>
                                <ResponsiveContainer width="80%" height="60%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={data}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="pv" barSize={20} fill="#8884d8" onClick={() => navigate("/financial/monthly-data")} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="col-span-3">
                                <p className="text-[16px] font-bold">
                                    Total Spend on each Ad
                                </p>

                                <div className="flex items-center gap-x-2 mt-5">
                                    <p className="text-[14px]">1)</p>
                                    <p className="text-[14px] w-[10rem] text-center py-1 bg-[#d9d9d9] rounded">
                                        Ad Name
                                    </p>
                                    <p className="text-[14px]">: $ 2,000</p>
                                </div>
                                <div className="flex items-center gap-x-2 mt-3">
                                    <p className="text-[14px]">2)</p>
                                    <p className="text-[14px] w-[10rem] text-center py-1 bg-[#d9d9d9] rounded">
                                        Ad Name
                                    </p>
                                    <p className="text-[14px]">: $ 1,500</p>
                                </div>
                                <div className="flex items-center gap-x-2 mt-3">
                                    <p className="text-[14px]">3)</p>
                                    <p className="text-[14px] w-[10rem] text-center py-1 bg-[#d9d9d9] rounded">
                                        Ad Name
                                    </p>
                                    <p className="text-[14px]">: $ 1,000</p>
                                </div>
                                <div className="flex items-center gap-x-2 mt-3">
                                    <p className="text-[14px]">4)</p>
                                    <p className="text-[14px] w-[10rem] text-center py-1 bg-[#d9d9d9] rounded">
                                        Ad Name
                                    </p>
                                    <p className="text-[14px]">: $ 250</p>
                                </div>
                                <div className="flex items-center gap-x-2 mt-3">
                                    <p className="text-[14px]">5)</p>
                                    <p className="text-[14px] w-[10rem] text-center py-1 bg-[#d9d9d9] rounded">
                                        Ad Name
                                    </p>
                                    <p className="text-[14px]">: $ 176</p>
                                </div>

                                <div className="w-fit mt-5">
                                    <div className="flex flex-col items-center relative">
                                        <p
                                            onClick={handleModifyAxis}
                                            className={`border border-black 
                                            ${showModifyAxis ? "bg-black text-white" : "bg-white text-black"} 
                                                px-[10px] py-[2px] text-[12px] cursor-pointer`
                                            }
                                        >
                                            Modify X/Y Axis Data
                                        </p>
                                        <div
                                            onClick={handleDateModify}
                                            className={`${showDateModify ? "w-full justify-center bg-black text-white" : "text-[#000000]"} 
                                            flex items-center gap-x-2 mt-2 cursor-pointer`
                                            }
                                        >
                                            <p className="text-[12px]">
                                                Date
                                            </p>
                                            <IoFilterSharp className="text-[18px]" />
                                        </div>
                                        {showModifyAxis &&
                                            <div className="absolute right-0 top-7 bg-white border border-black w-full z-10 p-2">
                                                <div className="grid grid-cols-12">
                                                    <div className="col-span-6">
                                                        <p className="text-[12px] text-start font-semibold mb-1">X - axis</p>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advertType"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">Date</p>
                                                        </div>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advertType"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">Month</p>
                                                        </div>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advertType"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">Years</p>
                                                        </div>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advertType"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">Clicks</p>
                                                        </div>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advertType"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">Views</p>
                                                        </div>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advertType"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">Ads</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-6">
                                                        <p className="text-[12px] text-start font-semibold mb-1">Y - axis</p>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advert"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">CP3C</p>
                                                        </div>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advert"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">CP3000I</p>
                                                        </div>
                                                        <div className="flex items-center gap-x-1 pb-1">
                                                            <input
                                                                type="radio"
                                                                name="advert"
                                                                className="cursor-pointer accent-[#00AB07]"
                                                            />
                                                            <p className="text-[10px] cursor-pointer">CP3V</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {showDateModify &&
                                            <div className="absolute right-0 top-[3.5rem] bg-white border border-black w-[15rem] z-10 p-2">
                                                <div className="grid grid-cols-12">
                                                    <div className="col-span-6">
                                                        <p className="text-[12px] text-start font-semibold mb-1">Start Date</p>
                                                        <input
                                                            type="date"
                                                            className="border border-black w-full px-2 py-1 text-[12px] focus:outline-none cursor-pointer"
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <p className="text-[12px] text-start font-semibold mb-1">End Date</p>
                                                        <input
                                                            type="date"
                                                            className="border border-black w-full px-2 py-1 text-[12px] focus:outline-none cursor-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Financials