import React, { useState } from "react";
import { demoAdvert } from "../../data";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { MdKeyboardArrowRight } from "react-icons/md";

const CreateCollection = () => {
    const navigate = useNavigate();
    const [collectionName, setCollectionName] = useState("");
    const [selectedAdverts, setSelectedAdverts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const handleAdvertSelection = (advertId) => {
        setSelectedAdverts((prev) =>
            prev.includes(advertId)
                ? prev.filter((id) => id !== advertId)
                : [...prev, advertId]
        );
    };

    const calculateTotalBidPrice = () => {
        return selectedAdverts.length * 0; // Assuming 0 as the default bid price since spentAmounts is removed
    };

    const handleCreateCollection = () => {
        if (!collectionName || selectedAdverts.length === 0) {
            setMessage("Please enter a collection name and select at least one advert.");
            return;
        }

        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setMessage("Collection created successfully!");
            console.log({
                collectionName,
                selectedAdverts,
            });
        }, 2000);
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* Breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/one-cent")} className="text-[#828282] cursor-pointer">1 - Cent Bidding</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Create Collection</p>
                </div>

                {/* Main Content */}
                <div className="grid grid-cols-12 gap-4 mt-4">
                    {/* Advert Selection */}
                    <div className="col-span-6">
                        <p className="text-center text-[16px] py-2 text-white bg-black rounded-md cursor-pointer">
                            Your created adverts - Select the adverts to create a collection
                        </p>
                        <div className="grid grid-cols-12 gap-2 mt-3">
                            {demoAdvert.map((item, index) => (
                                <div key={index} className="col-span-4">
                                    <div className="bg-[#ebe7e7] rounded-md p-2">
                                        <img
                                            alt="advert"
                                            src={item.image}
                                            className="w-full h-[150px] object-cover rounded-lg"
                                        />
                                        <div className="flex flex-col mt-2">
                                            <div className="flex items-center justify-between">
                                                <p className="text-gray-600 text-[11px]">Created At: {item.created}</p>
                                                <input
                                                    type="checkbox"
                                                    id={`advert-${index}`}
                                                    checked={selectedAdverts.includes(item.id)}
                                                    onChange={() => handleAdvertSelection(item.id)}
                                                    className="h-3 w-3 cursor-pointer accent-black text-white"
                                                />
                                            </div>
                                            <p className="text-black text-[14px] mt-1 line-clamp-1">{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Collection Details */}
                    <div className="col-span-6">
                        <p className="text-center text-[16px] py-2 text-white bg-black rounded-md cursor-pointer">
                            Create a new collection
                        </p>
                        <div className="mt-3">
                            <p className="text-[14px] font-semibold mb-1">
                                Collection Name
                            </p>
                            <input
                                type="text"
                                placeholder="Enter the name of the collection"
                                value={collectionName}
                                onChange={(e) => setCollectionName(e.target.value)}
                                className="w-full pl-5 pr-24 py-2 border border-[#5a5959] rounded-xl focus:outline-none"
                            />

                            <p className="text-[14px] font-semibold mt-3">
                                Collection Description
                            </p>
                            <textarea
                                rows="4"
                                placeholder="Enter the description of the collection"
                                className="w-full pl-5 pr-24 py-2 border border-[#5a5959] rounded-xl focus:outline-none mt-1 resize-none"
                            />
                            <div className="mt-3 flex items-center gap-x-2">
                                <p className="text-[14px] font-semibold mb-1">
                                    Maximum Bid Price for All Selected Adverts:
                                </p>
                                <p className="text-[20px] font-semibold mb-1 text-green-500">
                                    ${calculateTotalBidPrice()}
                                </p>
                            </div>
                            <div className="flex items-center justify-between mt-3">
                                <button
                                    onClick={() => navigate(-1)}
                                    className="bg-[#000000] hover:bg-[#cc3b3b] text-white px-4 py-1 rounded-full transition-all duration-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleCreateCollection}
                                    className="bg-[#000000] hover:bg-[#34bab1] text-white px-4 py-1 rounded-full transition-all duration-300"
                                >
                                    {loading ? "Creating..." : "Create"}
                                </button>
                            </div>
                            {message && <p className="mt-3 text-center text-red-500">{message}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCollection;