import React, { useState } from "react";
import Modal from "react-modal";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

Modal.setAppElement('#root');

const EditAccount = () => {
    const navigate = useNavigate();
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(file);
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add logic to save account details
        console.log("Account details updated!");
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Edit Account</p>
                </div>

                {/* main content */}
                <p className="mt-5 text-center text-[20px] font-bold underline underline-offset-[5px]">
                    Edit Account
                </p>

                <div className="flex justify-center">
                    <div className="mt-5 w-[35%] border border-gray-500 p-8 rounded-2xl">
                        <form onSubmit={handleSubmit} className="space-y-4">
                            {/* Profile Picture */}
                            <div className="flex flex-col items-center space-y-4">
                                <div>
                                    {previewImage ? (
                                        <img
                                            src={previewImage}
                                            alt="Preview"
                                            className="w-32 h-32 rounded-full border border-gray-300"
                                        />
                                    ) : (
                                        <img
                                            src="https://via.placeholder.com/150"
                                            alt="Default Profile"
                                            className="w-32 h-32 rounded-full border border-gray-300"
                                        />
                                    )}
                                </div>
                                <label className="cursor-pointer">
                                    <span className="py-[6px] px-6 text-[12px] bg-black text-white rounded-lg hover:bg-[#00AB07] transition-colors duration-300">
                                        Change Profile Picture
                                    </span>
                                    <input
                                        type="file"
                                        className="hidden"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </label>
                            </div>

                            {/* Name */}
                            <div>
                                <label className="block">Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter your name"
                                    className="w-full px-3 py-2 border border-gray-400 rounded-lg"
                                />
                            </div>

                            {/* Email */}
                            <div>
                                <label className="block">Email</label>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="w-full px-3 py-2 border border-gray-400 rounded-lg"
                                />
                            </div>

                            {/* Save Changes */}
                            <div className="flex items-center justify-between">
                                <button
                                    type="submit"
                                    className="py-2 px-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-300"
                                >
                                    Save Changes
                                </button>

                                <button
                                    type="button"
                                    onClick={() => setIsChangePasswordOpen(true)}
                                    className="py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
                                >
                                    Change Password
                                </button>
                            </div>
                        </form>

                        {/* Change Password Modal */}
                        <Modal
                            isOpen={isChangePasswordOpen}
                            onRequestClose={() => setIsChangePasswordOpen(false)}
                            contentLabel="Change Password"
                            className="fixed inset-0 flex items-center justify-center z-50"
                            overlayClassName="fixed inset-0 bg-black bg-opacity-70"
                        >
                            <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto">
                                <h2 className="text-xl font-semibold mb-4">Change Password</h2>
                                <form>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">Current Password</label>
                                        <input
                                            type="password"
                                            className="w-full px-3 py-2 border border-gray-400 rounded-lg"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">New Password</label>
                                        <input
                                            type="password"
                                            className="w-full px-3 py-2 border border-gray-400 rounded-lg"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">Confirm New Password</label>
                                        <input
                                            type="password"
                                            className="w-full px-3 py-2 border border-gray-400 rounded-lg"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <button
                                            type="button"
                                            onClick={() => setIsChangePasswordOpen(false)}
                                            className="py-2 px-4 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-300"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
                                        >
                                            Save Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAccount;
