import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { motion } from "framer-motion";
import { yourLocalAdvertisersData } from "../data";

const TierSystem = () => {
    const navigate = useNavigate();

    const animationSettings = {
        initial: { opacity: 0, y: -20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.1,
                ease: "easeOut",
            },
        },
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Tier</p>
                </div>

                {/* main content */}
                <motion.div
                    {...animationSettings}
                    className="flex items-center gap-x-3 mt-5 bg-[#F0F0F0] p-3 rounded-md shadow-md"
                >
                    <p className="text-[16px] font-bold">Your Tier : </p>
                    <p className="text-[14px] flex items-center bg-[#E0E0E0] text-[#626262] px-3 py-1 rounded-md">Tier 3</p>
                </motion.div>

                <motion.div
                    {...animationSettings}
                    className="border border-gray-400 rounded-lg p-4 mt-5 bg-[#FAFAFA] shadow-lg"
                >
                    <p className="text-[16px] font-bold">
                        Perks of your tier :
                    </p>
                    <p className="text-[14px] text-[#626262] mt-3">
                        Your advert will be played third in line based on a rolling play system.
                        This is based on your company economic scale and revenue. Your advert will
                        be played third in line based on a rolling play system. This is based on
                        your company economic scale and revenue. Your advert will be played third
                        in line based on a rolling play system. This is based on your company economic
                        scale and revenue. Your advert will be played third in line based on a rolling
                        play system. This is based on your company economic scale and revenue.
                    </p>
                </motion.div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="mt-10"
                >
                    <div className="flex items-center justify-between">
                        <p className="px-4 py-[5px] bg-[#D9D9D9] rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit">
                            Similar Advertisers
                        </p>

                        <p
                            onClick={() => navigate("/tier-system/similar-advertiser")}
                            className="px-4 py-[5px] bg-[#000000] text-white rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit"
                        >
                            view all
                        </p>
                    </div>

                    <div className="grid grid-cols-12 gap-5 mt-3">
                        {yourLocalAdvertisersData.map((data) => (
                            <motion.div
                                key={data.id}
                                variants={cardVariants}
                                className="col-span-3 border border-gray-300 bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105"
                            >
                                <p className="text-[18px] font-bold border-b border-gray-300 p-3 bg-gray-100">
                                    {data.name}
                                </p>
                                <div className="p-4">
                                    <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                    <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                    <div className="bg-gray-100 p-3 overflow-y-scroll h-[8rem] mt-1 rounded-md">
                                        <p className="text-[14px]">
                                            {data.about}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-4 mt-2">
                                        <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                        <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center mt-2 mb-8">
                                    <p
                                        onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                        className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] hover:bg-[#00AB20] px-8 py-2 rounded-md cursor-pointer"
                                    >
                                        Team - UP
                                    </p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default TierSystem;