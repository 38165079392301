import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';
import ReactSlider from 'react-slider';
import DatePicker from 'react-datepicker';
import countryList from 'react-select-country-list';
import 'react-datepicker/dist/react-datepicker.css';

const StageFour = () => {
    const [language, setLanguage] = useState([]);
    const [country, setCountry] = useState(null);
    const [states, setStates] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [gender, setGender] = useState([]);
    const [ageRange, setAgeRange] = useState([18, 65]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [duration, setDuration] = useState({ totalDays: 0, weekends: false, startDate: null, endDate: null });

    const countryOptions = countryList().getData();

    useEffect(() => {
        if (country) {
            axios.post('https://countriesnow.space/api/v0.1/countries/states', { country: country.label })
                .then(response => {
                    const stateOptions = response.data.data.states.map(state => ({
                        value: state.name,
                        label: state.name
                    }));
                    setStates([{ value: 'all', label: 'All States' }, ...stateOptions]);
                })
                .catch(error => {
                    console.error('Error fetching states:', error);
                });
        }
    }, [country]);

    useEffect(() => {
        if (selectedStates.length > 0) {
            const stateNames = selectedStates.map(state => state.label);
            axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', { country: country.label, state: stateNames[0] })
                .then(response => {
                    const cityOptions = response.data.data.map(city => ({
                        value: city,
                        label: city
                    }));
                    setCities([{ value: 'all', label: 'All Cities' }, ...cityOptions]);
                })
                .catch(error => {
                    console.error('Error fetching cities:', error);
                });
        }
    }, [selectedStates]);

    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ];

    const languageOptions = [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'French', label: 'French' }
    ];

    const dayOptions = [
        { value: 'monday', label: 'Monday' },
        { value: 'tuesday', label: 'Tuesday' },
        { value: 'wednesday', label: 'Wednesday' },
        { value: 'thursday', label: 'Thursday' },
        { value: 'friday', label: 'Friday' },
        { value: 'saturday', label: 'Saturday' },
        { value: 'sunday', label: 'Sunday' }
    ];

    return (
        <Fragment>
            <div className="grid grid-cols-12 gap-10 mt-5">
                <div className="col-span-6">
                    <p className="text-[16px] font-bold">Choose Location</p>
                    <div className="relative w-full mt-3">
                        <Select
                            options={countryOptions}
                            value={country}
                            onChange={setCountry}
                            placeholder="Select Country"
                        />
                    </div>
                    <div className="relative w-full mt-3">
                        <Select
                            options={states}
                            value={selectedStates}
                            onChange={setSelectedStates}
                            isMulti
                            placeholder="Select States"
                        />
                    </div>
                    <div className="relative w-full mt-3">
                        <Select
                            options={cities}
                            value={selectedCities}
                            onChange={setSelectedCities}
                            isMulti
                            placeholder="Select Cities"
                        />
                    </div>
                </div>
                <div className="col-span-6">
                    <p className="text-[16px] font-bold">Choose Language</p>
                    <div className="relative w-full mt-3">
                        <Select
                            options={languageOptions}
                            value={language}
                            onChange={setLanguage}
                            isMulti
                            placeholder="Select Languages"
                        />
                    </div>

                    <p className="text-[16px] font-bold mt-5">Choose Age Range</p>
                    <div className="relative w-full mt-3">
                        <ReactSlider
                            className="w-full h-2 bg-gray-300 rounded-md"
                            thumbClassName="h-5 w-5 bg-blue-800 -mt-[6px] rounded-full cursor-pointer"
                            trackClassName="h-2 bg-blue-500 rounded-md"
                            value={ageRange}
                            onChange={setAgeRange}
                            min={0}
                            max={100}
                            pearling
                            minDistance={1}
                        />
                        <div className="flex justify-between mt-2">
                            <span>{ageRange[0]}</span>
                            <span>{ageRange[1]}</span>
                        </div>
                    </div>
                </div>
                <div className="col-span-6">
                    <p className="text-[16px] font-bold">Choose Gender</p>
                    <div className="relative w-full mt-3">
                        <Select
                            options={genderOptions}
                            value={gender}
                            onChange={setGender}
                            isMulti
                            placeholder="Select Genders"
                        />
                    </div>
                </div>
                <div className="col-span-12">
                    <p className="text-[16px] font-bold">Duration</p>
                    <div className="flex items-center gap-x-3">
                        <div className="relative mt-3">
                            <DatePicker
                                selected={duration.startDate}
                                onChange={(date) => setDuration({ ...duration, startDate: date })}
                                selectsStart
                                startDate={duration.startDate}
                                endDate={duration.endDate}
                                placeholderText="Select Start Date"
                                className="w-full bg-[#eae8e8] px-3 py-[10px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            />
                        </div>
                        <div className="relative mt-3">
                            <DatePicker
                                selected={duration.endDate}
                                onChange={(date) => setDuration({ ...duration, endDate: date })}
                                selectsEnd
                                startDate={duration.startDate}
                                endDate={duration.endDate}
                                minDate={duration.startDate}
                                placeholderText="Select End Date"
                                className="w-full bg-[#eae8e8] px-3 py-[10px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            />
                        </div>
                    </div>
                    <div className="relative w-full mt-3">
                        <Select
                            isMulti
                            options={dayOptions}
                            value={selectedDays}
                            onChange={setSelectedDays}
                            placeholder="Select Days"
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default StageFour;