import React, { useState } from "react";
import { oneCentBidData } from "../data";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";

const OneCentBidding = () => {
    const navigate = useNavigate();
    const [filterBy, setFilterBy] = useState("");
    const [filterType, setFilterType] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">1 - Cent Bidding</p>
                </div>

                {/* main content */}
                <div className="flex items-center justify-between mt-5">
                    <p className="text-[18px] font-bold">
                        Choose an Advert to Bid on !
                    </p>
                    <div className="flex items-center gap-x-2">
                        <div className="relative">
                            <select
                                required
                                value={filterBy}
                                onChange={(e) => setFilterBy(e.target.value)}
                                className="w-[18rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Filter Ads by Date">Filter Ads by Date</option>
                                <option value="Filter Ads by Week">Filter Ads by Week</option>
                                <option value="Filter Ads by Month">Filter Ads by Month</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                        <div className="relative">
                            <select
                                required
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                                className="w-[18rem] bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Filter Ads by Type">Filter Ads by Type</option>
                                <option value="Filter Ads by Type 2">Filter Ads by Type 2</option>
                                <option value="Filter Ads by Type 3">Filter Ads by Type 3</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="relative mt-5">
                    <input
                        type="text"
                        placeholder="Search for an Advert"
                        className="w-[30%] pl-8 pr-2 py-2 border border-[#b1b1b1] text-[14px] rounded-full focus:outline-none"
                    />
                    <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
                        <IoSearchSharp
                            className="text-[20px] text-[#000000] hover:text-[#00AB07] cursor-pointer"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-5 mt-5">
                    {oneCentBidData.map((data) => (
                        <div className="col-span-3" key={data.id}>
                            <p className="text-center bg-[#d9d9d9] text-[14px] font-bold py-1">
                                {data.name} Ad
                            </p>
                            <div className="border border-[#c3c3c3] p-3 mt-2">
                                <img
                                    alt="advert"
                                    src={data.image}
                                    className="w-full h-[10rem] object-cover"
                                />
                                <div className="mt-2 text-[14px]">
                                    <div className="flex items-center justify-between">
                                        <p>CP3V :</p>
                                        <p>Tier : 1</p>
                                    </div>
                                    <p>CP3C :</p>
                                    <p>CP3000 Impressions :</p>
                                    <p>Expenditure :</p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <button 
                                        onClick={() => navigate(`/one-cent/details/${data.id}`)}
                                        className="text-[14px] text-[#ffffff] bg-[#00AB07] px-6 py-1 rounded mt-5"
                                    >
                                        Bid
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OneCentBidding