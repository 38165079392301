import React from "react";
import { useNavigate } from "react-router-dom";
import notFoundImage from "../assets/common/error-404.png"

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col justify-center items-center mt-[8rem]">
            <img 
                alt="Not Found" 
                src={notFoundImage} 
                className="w-[20rem]"
            />
            <p className="text-[#828282] text-[34px] font-bold mt-5">
                <span className="text-[#00C2FF] font-bold">404</span> | Page Not
            </p>
            <button onClick={() => navigate("/")} className="bg-[#00C2FF] text-[#ffffff] px-5 py-2 rounded-md mt-5">
                Go Back Home
            </button>
        </div>
    )
}

export default NotFound