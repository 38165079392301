import React, { Fragment } from "react";
import MainRoutes from "./router/MainRoutes";

const App = () => {
    return (
        <Fragment>
            <MainRoutes />
        </Fragment>
    )
}

export default App