import React from "react";
import { GrTableAdd } from "react-icons/gr";
import { FaTrashAlt } from "react-icons/fa";
import { activeCollection } from "../../data";
import { useNavigate } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import { GoQuestion, GoHomeFill } from "react-icons/go";
import { MdKeyboardArrowRight, MdEditDocument } from "react-icons/md";

const AdvertCollection = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                        <div
                            onClick={() => navigate("/")}
                            className="flex items-center gap-x-[4px] cursor-pointer"
                        >
                            <GoHomeFill className="text-[16px]" />
                            <p>Home</p>
                        </div>
                        <MdKeyboardArrowRight className="text-[16px]" />
                        <p onClick={() => navigate("/one-cent")} className="text-[#828282] cursor-pointer">1 - Cent Bidding</p>
                        <MdKeyboardArrowRight className="text-[16px]" />
                        <p className="text-[#00C2FF] cursor-pointer">Ad Collection</p>
                    </div>
                    <button
                        // onClick={() => navigate("/create-advert")}
                        className="flex items-center bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] px-3 py-2 text-[14px] rounded-md"
                    >
                        <GoQuestion className="mr-2 text-[22px]" /> How Collection Works
                    </button>
                </div>

                {/* main content */}
                <div className="flex items-center justify-between gap-x-5 mt-5">
                    <h2 className="text-[24px] font-bold text-[#1c1c1c]">Advert Collection</h2>
                    <div className="flex items-center gap-x-3">
                        <button
                            onClick={() => navigate("/create-advert")}
                            className="flex items-center bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] px-3 py-2 text-[14px] rounded-md"
                        >
                            <IoMdAddCircle className="mr-2 text-[22px]" /> Create New Advert
                        </button>
                        <button
                            onClick={() => navigate("/one-cent/create-collection")}
                            className="flex items-center bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] px-3 py-2 text-[14px] rounded-md"
                        >
                            <GrTableAdd className="mr-2 text-[18px]" /> Create Collection
                        </button>
                    </div>
                </div>

                {/* active advert */}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5">
                    {activeCollection.map((advert) => (
                        <div
                            key={advert.id}
                            className="bg-gradient-to-br from-white to-gray-100 border border-gray-300 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
                        >
                            <div className="flex flex-col h-full justify-between">
                                <div className="relative" onClick={() => navigate(`/one-cent/view-collection/${advert.id}`)}>
                                    <h3 className="text-xl font-semibold text-gray-800 line-clamp-1">{advert.advertName} - Advert Collection</h3>
                                    <div className="absolute top-9 right-0">
                                        <span
                                            className={`px-3 py-1 text-sm font-medium rounded-full ${advert.status === "Active"
                                                ? "bg-green-100 text-green-800"
                                                : "bg-red-100 text-red-800"
                                                }`}
                                        >
                                            {advert.status}
                                        </span>
                                    </div>
                                    <div className="space-y-2 mt-2">
                                        <p className="text-gray-600">
                                            <span className="font-medium">Maximum Bid Price:</span>
                                            <span className="font-medium ml-1">${advert.maxBidAmount}</span>
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Collection Created:</span>
                                            <span className="font-medium ml-1">{advert.createdDate}</span>
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Last Updated:</span>
                                            <span className="font-medium ml-1">{advert.updatedlast}</span>
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Total Bid Placed:</span>
                                            <span className="font-medium ml-1">{advert.bidPlaced} Time</span>
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Description:</span>
                                            <span className="font-medium ml-1">{advert.description}</span>
                                        </p>
                                    </div>

                                    <p className="mt-4 mb-2 font-semibold text-gray-700">
                                        Total {advert.advertCollectionId.length} Advert Attached
                                    </p>
                                    <div className="flex flex-wrap gap-2">
                                        {advert.advertCollectionId.slice(0, 4).map((id) => (
                                            <span
                                                key={id}
                                                className="bg-blue-100 text-blue-800 text-xs font-medium px-3 py-1 rounded-full"
                                            >
                                                {id}
                                            </span>
                                        ))}
                                        {advert.advertCollectionId.length > 4 && (
                                            <span className="bg-gray-300 text-gray-800 text-xs font-medium px-3 py-1 cursor-pointer rounded-full">
                                                More +{advert.advertCollectionId.length - 4}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center justify-between gap-x-6 z-10">
                                    <button
                                        onClick={() => navigate(`/one-cent/create-collection`)}
                                        className="w-full flex items-center justify-center bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] px-3 py-2 text-[14px] rounded-md mt-4"
                                    >
                                        <FaTrashAlt className="mr-2 text-[14px]" /> Delete Collection
                                    </button>
                                    <button
                                        onClick={() => navigate(`/one-cent/create-collection`)}
                                        className="w-full flex items-center justify-center bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] px-3 py-2 text-[14px] rounded-md mt-4"
                                    >
                                        <MdEditDocument className="mr-2 text-[16px]" /> Edit Collection
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AdvertCollection;