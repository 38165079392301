import React from "react";
import { currentTeamUp } from "../../data";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const CurrentTeampUp = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Current Team - Up</p>
                </div>

                {/* Your Current Team - Up */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Your Current Team - Ups
                </p>
                <div className="grid grid-cols-12 gap-5 mt-10">
                    {currentTeamUp.map((data) => (
                        <div className="col-span-3" key={data.id}>
                            <p className="text-center font-bold pb-2 text-[18px] text-gray-900">{data.name} Team - Up</p>
                            <div className="border border-gray-400 p-3 rounded">
                                <p className="text-center text-[14px] font-bold py-2">Joint Advert</p>
                                <div className="overflow-hidden rounded-lg">
                                    <img
                                        src={data.img}
                                        alt={data.name}
                                        className="h-[12rem] w-full object-cover transition-transform duration-300 hover:scale-110"
                                    />
                                </div>
                                <p className="text-center text-[14px] font-bold py-2">
                                    Teamed - Up : {data.dateTime}
                                </p>
                                <p className="text-center text-[14px] font-bold pb-2">
                                    Total Interactions : {data.interactions}
                                </p>
                                <div className="flex items-center justify-center">
                                    <button
                                        onClick={() => navigate(`/team-up/current/${data.id}`)}
                                        className="w-full bg-[#00AB07] text-white py-1 rounded">
                                        See Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CurrentTeampUp