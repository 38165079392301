import React, { useState } from "react";
// import { HiBars3 } from "react-icons/hi2";
import { CiLogout } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
// import { MdOutlineClose } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaHandPaper, FaUser } from "react-icons/fa";
import { BsFillQuestionDiamondFill } from "react-icons/bs";
import { FaCirclePlus, FaCaretDown } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
// import { motion } from "framer-motion";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const [sideMenu, setSideMenu] = useState(false);
    const [dropDownMenu, setDropDownMenu] = useState("Advertisers Business");
    const [showDropDownMenu, setShowDropDownMenu] = useState(false);

    // const sliderMenuItems = [
    //     { id: 1, name: "Your Adverts", pathname: "/your-advert" },
    //     { id: 2, name: "1 - Cent Bidding", pathname: "/one-cent" },
    //     { id: 3, name: "Billing and Invoice", pathname: "" },
    //     { id: 4, name: "Edit Account", pathname: "/account/edit" },
    //     { id: 5, name: "Trending Ads", pathname: "" },
    //     { id: 6, name: "Contact Us", pathname: "/contact-us" },
    //     { id: 7, name: "Contact Expert", pathname: "" },
    //     { id: 8, name: "Settings", pathname: "/settings" },
    // ];

    const headerMenuItems = [
        { id: 1, name: "Login", pathname: "/login", icon: FaUser },
        { id: 2, name: "Account Balance: $10,000", pathname: "/account", icon: FaMoneyCheckAlt },
        { id: 3, name: "Create Adverts", pathname: "/create-advert", icon: FaCirclePlus },
        { id: 4, name: dropDownMenu, pathname: "", icon: FaCaretDown, isDropdown: true },
        { id: 5, name: "Keywords", pathname: "/keywords", icon: IoSearchSharp },
        { id: 6, name: "How It Works", pathname: "/how", icon: BsFillQuestionDiamondFill },
        { id: 7, name: "Help Mode", pathname: "/help-mode", icon: FaHandPaper },
    ];

    const dropdownItems = [
        { id: 1, name: "Menu One" },
        { id: 2, name: "Menu Two" },
        { id: 3, name: "Menu Three" },
    ];

    const handleLogout = () => {
        sessionStorage.removeItem("userSession");
        navigate("/security-pass");
    };

    return (
        <div className="sticky top-0 flex items-center justify-between bg-[#D6EFD8] px-3 py-4 z-50 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
            <p
                onClick={() => navigate("/")}
                className={`bg-[#00AB07] px-8 py-2 text-[#ffffff] text-[20px] font-semibold cursor-pointer rounded`}
            >
                K.A.S
            </p>

            {headerMenuItems.map((item) => (
                <div
                    key={item.id}
                    onClick={() => item.isDropdown ? setShowDropDownMenu(!showDropDownMenu) : navigate(item.pathname)}
                    className={`flex items-center gap-x-2 px-5 py-2 cursor-pointer rounded relative transition-colors duration-500 
                        ${location.pathname === item.pathname ? "bg-[#000000] text-[#ffffff]" : "bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff]"}`}
                >
                    {item.icon && <item.icon className="text-[20px]" />}
                    <p className="text-[14px]">
                        {item.name}
                    </p>
                    {item.isDropdown && showDropDownMenu &&
                        <div className="absolute top-11 left-0 w-full bg-black">
                            {dropdownItems.map((dropdownItem) => (
                                <p
                                    key={dropdownItem.id}
                                    onClick={() => setDropDownMenu(dropdownItem.name)}
                                    className="text-[13px] text-white p-2 hover:bg-[#3d3c3c] border-b border-[#7d7b7b]"
                                >
                                    {dropdownItem.name}
                                </p>
                            ))}
                        </div>
                    }
                </div>
            ))}

            {location.pathname !== "/login" &&
                <div
                    onClick={handleLogout}
                    className="flex items-center gap-x-1 px-5 py-2 bg-[#ffffff] text-[#000000] hover:bg-[#000000] hover:text-[#ffffff] cursor-pointer rounded transition-colors duration-300"
                >
                    <CiLogout className="text-[20px]" />
                    <p className="text-[14px]">Logout</p>
                </div>
            }

            {/* <div
                onClick={() => setSideMenu(!sideMenu)}
                className={`p-[6px] ${sideMenu ? "bg-[#000000] text-[#ffffff]" : "bg-[#ffffff]"} 
                    cursor-pointer rounded hover:bg-[#000000] hover:text-[#ffffff] transition-colors duration-500`}
            >
                {sideMenu ?
                    <MdOutlineClose className="text-[25px]" /> :
                    <HiBars3 className="text-[25px]" />
                }
            </div>
            {sideMenu &&
                <motion.div
                    initial={{ x: "100%" }}
                    animate={{ x: sideMenu ? 0 : "100%" }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    className="absolute right-0 top-[5rem] bg-[#4F4949] px-6 pb-3 pt-6"
                >
                    {sliderMenuItems.map((item) => (
                        <div key={item.id} className="mb-3">
                            <p
                                onClick={() => navigate(`${item.pathname}`)}
                                className="w-[10rem] text-center py-2 text-[14px] bg-[#ffffff] hover:bg-[#000000] hover:text-[#ffffff] cursor-pointer"
                            >
                                {item.name}
                            </p>
                        </div>
                    ))}
                </motion.div>
            } */}
        </div>
    )
}

export default Header;