import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const DeleteAccount = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Delete Account</p>
                </div>

                {/* main content */}
                <p className="mt-5 text-center text-[20px] font-bold underline underline-offset-[5px]">
                    Delete Account
                </p>

                <div className="border border-[#928b8b] mt-5 mx-[6rem] p-5 rounded">
                    <p className="text-[14px] text-center">
                        Are you sure you want to delete your account and stop all advertising?
                    </p>
                    <div className="mt-10 flex items-start justify-center">
                        <div className="text-[14px]">
                            <p className="mb-1">⦁ Deleting will stop all advertisements</p>
                            <p className="mb-1">⦁ You will not receive any refunds</p>
                            <p className="mb-1">⦁ And we will be sad to see you go!</p>
                        </div>
                    </div>

                    <p className="text-[14px] text-center mt-10">
                        You’ll be able to restart your account for the next 30 days if you change your mind
                    </p>

                    <div className="flex items-center gap-x-5 justify-center mt-10">
                        <p>Password:</p>
                        <input
                            type="text"
                            placeholder="Enter your password"
                            className="w-[25%] border-none bg-[#d1cdcd] focus:outline-none p-2 rounded placeholder:text-[#595555]"
                        />
                    </div>

                    <div className="flex flex-col items-center justify-center mt-5">
                        <butoon className="text-[14px] px-5 py-2 rounded text-[#ffffff] bg-[#0DD315]">
                            Delete
                        </butoon>
                        <p className="text-[14px] px-5 py-2 rounded text-[#ffffff] bg-[#640D5F] mt-5">
                            Your Total Interactions : 527,000
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount