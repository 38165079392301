import React, { useState } from "react";
import { IoFilterSharp } from "react-icons/io5";
import { IoInformationCircle } from "react-icons/io5";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const Home = () => {
    const [bidCategory, setBidCategory] = useState("");
    const [bidSubmited, setBidSubmited] = useState(false);
    const [showModifyAxis, setShowModifyAxis] = useState(false);
    const [showDateModify, setShowDateModify] = useState(false);
    const [showSummary, setShowSummary] = useState(false);

    const data = [
        { name: 'Monday', uv: 4000, pv: 2400, amt: 2400, },
        { name: 'Tuesday', uv: 3000, pv: 1398, amt: 2210, },
        { name: 'Wednesday', uv: 2000, pv: 9800, amt: 2290, },
        { name: 'Thursday', uv: 2780, pv: 3908, amt: 2000, },
        { name: 'Friday', uv: 1890, pv: 4800, amt: 2181, },
        { name: 'Saturday', uv: 2390, pv: 3800, amt: 2500, },
        { name: 'Sunday', uv: 3490, pv: 4300, amt: 2100, },
    ];

    const handleModifyAxis = () => {
        setShowModifyAxis(!showModifyAxis);
        setShowDateModify(false);
    }

    const handleDateModify = () => {
        setShowDateModify(!showDateModify);
        setShowModifyAxis(false);
    }

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll mt-[1rem] px-3">
                {/* section 01 */}


                {/* section 02 */}
                <div className="grid grid-cols-12 gap-x-5 mt-14">
                    <div className="col-span-12">
                        <div className="flex items-start justify-between">
                            <div className="flex items-center gap-x-3 relative">
                                <p className="text-[20px] font-bold">
                                    Summary : 22 Nov 2024
                                </p>
                                <IoFilterSharp
                                    onClick={() => setShowSummary(!showSummary)}
                                    className="text-[30px] cursor-pointer"
                                />

                                {showSummary &&
                                    <div className="absolute left-0 top-7 bg-[#ffffff] border border-black w-[15rem] z-10 p-2">
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-6">
                                                <p className="text-[12px] text-start font-semibold mb-1">Start Date</p>
                                                <input
                                                    type="date"
                                                    className="border border-black w-full px-2 py-1 text-[12px] focus:outline-none cursor-pointer"
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <p className="text-[12px] text-start font-semibold mb-1">End Date</p>
                                                <input
                                                    type="date"
                                                    className="border border-black w-full px-2 py-1 text-[12px] focus:outline-none cursor-pointer"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="flex flex-col items-center relative">
                                <p
                                    onClick={handleModifyAxis}
                                    className={`border border-black 
                                ${showModifyAxis ? "bg-black text-white" : "bg-white text-black"} 
                                px-[10px] py-[2px] text-[12px] cursor-pointer`
                                    }
                                >
                                    Modify X/Y Axis Data
                                </p>
                                <div
                                    onClick={handleDateModify}
                                    className={`${showDateModify ? "w-full justify-center bg-black text-white" : "text-[#000000]"} 
                                flex items-center gap-x-2 mt-2 cursor-pointer`
                                    }
                                >
                                    <p className="text-[12px]">
                                        Date
                                    </p>
                                    <IoFilterSharp className="text-[18px]" />
                                </div>
                                {showModifyAxis &&
                                    <div className="absolute right-0 top-7 bg-white border border-black w-full z-10 p-2">
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-6">
                                                <p className="text-[12px] text-start font-semibold mb-1">X - axis</p>
                                                <div className="flex items-center gap-x-1 pb-1">
                                                    <input
                                                        type="radio"
                                                        className="cursor-pointer accent-[#00AB07]"
                                                    />
                                                    <p className="text-[10px] cursor-pointer">$ Spent</p>
                                                </div>
                                                <div className="flex items-center gap-x-1 pb-1">
                                                    <input
                                                        type="radio"
                                                        className="cursor-pointer accent-[#00AB07]"
                                                    />
                                                    <p className="text-[10px] cursor-pointer">Month</p>
                                                </div>
                                                <div className="flex items-center gap-x-1 pb-1">
                                                    <input
                                                        type="radio"
                                                        className="cursor-pointer accent-[#00AB07]"
                                                    />
                                                    <p className="text-[10px] cursor-pointer">Date</p>
                                                </div>
                                            </div>
                                            <div className="col-span-6">
                                                <p className="text-[12px] text-start font-semibold mb-1">Y - axis</p>
                                                <div className="flex items-center gap-x-1 pb-1">
                                                    <input
                                                        type="radio"
                                                        className="cursor-pointer accent-[#00AB07]"
                                                    />
                                                    <p className="text-[10px] cursor-pointer">CP3C</p>
                                                </div>
                                                <div className="flex items-center gap-x-1 pb-1">
                                                    <input
                                                        type="radio"
                                                        className="cursor-pointer accent-[#00AB07]"
                                                    />
                                                    <p className="text-[10px] cursor-pointer">CP30I</p>
                                                </div>
                                                <div className="flex items-center gap-x-1 pb-1">
                                                    <input
                                                        type="radio"
                                                        className="cursor-pointer accent-[#00AB07]"
                                                    />
                                                    <p className="text-[10px] cursor-pointer">CP3V</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {showDateModify &&
                                    <div className="absolute right-0 top-[3.5rem] bg-white border border-black w-[15rem] z-10 p-2">
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-6">
                                                <p className="text-[12px] text-start font-semibold mb-1">Start Date</p>
                                                <input
                                                    type="date"
                                                    className="border border-black w-full px-2 py-1 text-[12px] focus:outline-none cursor-pointer"
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <p className="text-[12px] text-start font-semibold mb-1">End Date</p>
                                                <input
                                                    type="date"
                                                    className="border border-black w-full px-2 py-1 text-[12px] focus:outline-none cursor-pointer"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 border border-black rounded-md">
                        <div className="flex items-center gap-x-2 mb-2 px-5 pt-4">
                            <p className="text-[18px]">Overall Reach :</p>
                            <p className="text-[18px] font-bold">825K</p>
                        </div>
                        <div className="flex items-center gap-x-2 mb-2 px-5">
                            <p className="text-[18px]">Cost per 3 clicks :</p>
                            <p className="text-[18px] font-bold">$5</p>
                        </div>
                        <div className="flex items-center gap-x-2 mb-2 px-5">
                            <p className="text-[18px]">Cost per 3000 impressions :</p>
                            <p className="text-[18px] font-bold">$10</p>
                        </div>
                        <div className="flex items-center gap-x-2 mb-2 px-5">
                            <p className="text-[18px]">Cost per 3 views :</p>
                            <p className="text-[18px] font-bold">$2</p>
                        </div>
                        <div className="flex items-center gap-x-2 mb-2 px-5">
                            <p className="text-[18px]">Your tier :</p>
                            <input
                                type="text"
                                className="border border-black px-2 py-1 w-[5rem] text-[14px] text-center focus:outline-none"
                            />
                        </div>
                        <div className="flex items-center gap-x-2 mb-4 px-5">
                            <p className="text-[18px]">Your total expenditure : </p>
                            <p className="text-[18px] font-bold"></p>
                        </div>
                        <p className="border-t border-black px-5 py-2 text-[18px] font-bold">
                            $800
                        </p>
                    </div>
                    <div className="col-span-6">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* section 03 */}
                <div className="grid grid-cols-12 gap-x-5 mt-7">
                    <div className="col-span-6">
                        <div className="w-full h-[18rem] flex items-center justify-center bg-[#00C2FF99] rounded-md cursor-pointer shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                            <p className="text-[22px] font-bold text-center">
                                View current <br /> advertisements on display
                            </p>
                        </div>
                    </div>
                    <div className="col-span-6">
                        <div className={`w-full h-[18rem] ${bidSubmited ? "bg-[#00AB07]" : "bg-[#AC2F39]"}  rounded-md p-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)]`}>
                            {!bidSubmited &&
                                <div className="grid grid-cols-12 items-center gap-y-3">
                                    <div className="col-span-6">
                                        <div className="flex items-center gap-x-2">
                                            <IoInformationCircle className="text-[#ffffff] text-[16px] cursor-pointer" />
                                            <p className="text-[14px] text-[#ffffff]">
                                                Choose Ad :
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-6">
                                        <div className="relative w-full">
                                            <select
                                                required
                                                value={bidCategory}
                                                onChange={(e) => setBidCategory(e.target.value)}
                                                className="w-full bg-[#ffffff] px-3 py-[8px] md:py-[8px] text-black text-[14px] border border-[#ffffff] rounded-md focus:outline-none appearance-none "
                                            >
                                                <option value={""}>Select Advert</option>
                                                <option value="Vanilla Cookies Advert">Vanilla Cookies Advert</option>
                                                <option value="Chocolate Advert ">Chocolate Advert </option>
                                                <option value="Peanut Butter Advert">Peanut Butter Advert</option>
                                            </select>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="col-span-6">
                                        <div className="flex items-center gap-x-2">
                                            <IoInformationCircle className="text-[#ffffff] text-[16px] cursor-pointer" />
                                            <p className="text-[14px] text-[#ffffff]">
                                                Maximum Advert Reserve Amount {"($)"} :
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-6">
                                        <input
                                            type="text"
                                            className="border border-[#ffffff] w-full px-2 py-[8px] md:py-[8px] text-[14px] focus:outline-none rounded-md"
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <div className="flex items-center gap-x-2">
                                            <IoInformationCircle className="text-[#ffffff] text-[16px] cursor-pointer" />
                                            <p className="text-[14px] text-[#ffffff]">
                                                Maximum Bid Price {"($)"} :
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-6">
                                        <input
                                            type="text"
                                            className="border border-[#ffffff] w-full px-2 py-[8px] md:py-[8px] text-[14px] focus:outline-none rounded-md"
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <div className="flex items-center gap-x-2">
                                            <IoInformationCircle className="text-[#ffffff] text-[16px] cursor-pointer" />
                                            <p className="text-[14px] text-[#ffffff]">
                                                Maximum Spend Per Day {"($)"} :
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-6">
                                        <input
                                            type="text"
                                            className="border border-[#ffffff] w-full px-2 py-[8px] md:py-[8px] text-[14px] focus:outline-none rounded-md"
                                        />
                                    </div>
                                    <div className="col-span-12 flex items-center justify-center mt-4">
                                        <button
                                            onClick={() => setBidSubmited(true)}
                                            className="text-[16px] w-[10rem] py-2 rounded bg-[#00AB07] text-[#ffffff]"
                                        >
                                            Place bid
                                        </button>
                                    </div>
                                </div>
                            }
                            {bidSubmited &&
                                <div
                                    onClick={() => setBidSubmited(!bidSubmited)}
                                    className="flex flex-col gap-y-8 items-center justify-center h-full"
                                >
                                    <p className="text-[18px] text-[#ffffff] text-center w-[70%]">
                                        Congratulations! Your maximum bid interaction of $0.50
                                        has been placed! Your minimum interaction price has been set at $0.01.
                                    </p>
                                    <p className="text-[20px] text-[#640D5F] font-bold text-center">
                                        Your Order Number: #13024
                                    </p>
                                    <p className="text-[12px] text-[#ffffff] text-center">
                                        Oct 12, 2024 02:05 pm
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home