import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Performance = () => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(true);
    const [ads, setAds] = useState("");
    const [clicks, setClicks] = useState("");
    const [date, setDate] = useState("");

    const data = [
        { name: 'JAN', uv: 0 },
        { name: 'FEB', uv: 8000 },
        { name: 'MAR', uv: 3000 },
        { name: 'APR', uv: 1526 },
        { name: 'MAY', uv: 4589 },
        { name: 'JUN', uv: 2390 },
        { name: 'JUL', uv: 3490 },
        { name: 'AUG', uv: 5000 },
        { name: 'SEP', uv: 1000 },
        { name: 'OCT', uv: 7000 },
        { name: 'NOV', uv: 3584 },
        { name: 'DEC', uv: 2569 },
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Performance</p>
                </div>

                {/* main content */}
                {showPopup &&
                    <div className="flex items-center justify-center">
                        <div className="w-[30%] border border-[#000000] px-5 py-2 rounded mt-[5rem]">
                            <div className="flex justify-end">
                                <IoClose
                                    onClick={() => setShowPopup(false)}
                                    className="text-[24px] text-[#000000] hover:text-[#f35252] cursor-pointer"
                                />
                            </div>
                            <p className=" text-center text-[18px] font-bold mt-2">
                                Welcome to your Performance Page!
                            </p>
                            <p className=" text-center text-[14px] mt-6">
                                Here you can view and compare how your adverts are performing on a graph!
                            </p>
                            <p className=" text-center text-[14px] mt-6">
                                Use the different filter options to change the graph!
                            </p>
                            <div className="flex items-center justify-center my-10">
                                <button
                                    onClick={() => setShowPopup(false)}
                                    className="bg-[#0DD315] text-white px-3 py-1 rounded"
                                >
                                    Continue to Page
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {!showPopup &&
                    <div className="mt-5">
                        <p className="text-[16px]">
                            Here you can see your overall performance or performance of individual Ads
                        </p>
                        <div className="grid grid-cols-12 gap-5 mt-5">
                            <div className="col-span-4">
                                <div className="relative w-[50%]">
                                    <select
                                        required
                                        value={ads}
                                        onChange={(e) => setAds(e.target.value)}
                                        className="w-full bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                                    >
                                        <option value={""}>View all Ads</option>
                                        <option value="Cookies Ad">Cookies Ad</option>
                                        <option value="Vanilla Ad">Vanilla Ad</option>
                                        <option value="Chocolate Ad">Chocolate Ad</option>
                                    </select>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                    </svg>
                                </div>
                            </div>
                            <div className="col-span-4">
                                <div className="relative w-[50%]">
                                    <select
                                        required
                                        value={clicks}
                                        onChange={(e) => setClicks(e.target.value)}
                                        className="w-full bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                                    >
                                        <option value={""}>Clicks</option>
                                        <option value="# Views"># Views</option>
                                        <option value="# Interactions"># Interactions</option>
                                        <option value="Total Traffic">Total Traffic</option>
                                    </select>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                    </svg>
                                </div>
                            </div>
                            <div className="col-span-4">
                                <div className="relative w-[50%]">
                                    <select
                                        required
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        className="w-full bg-[#d9d9d9] px-3 py-[6px] text-black text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                                    >
                                        <option value={""}>Date</option>
                                        <option value="Month">Month</option>
                                        <option value="Week">Week</option>
                                        <option value="Year">Year</option>
                                    </select>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                    </svg>
                                </div>
                            </div>
                            <div className="col-span-12 mt-14">
                                <ResponsiveContainer width="100%" height={350}>
                                    <LineChart
                                        width={500}
                                        height={200}
                                        data={data}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line connectNulls type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="col-span-12 mt-5">
                                <div className="flex items-center justify-center gap-x-2">
                                    <p className="text-[14px]">Want to increase performance?</p>
                                    <p className="text-[14px] text-[#00C2FF] cursor-pointer">Bid Now</p>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Performance