import React from "react";
import SideMenu from "../components/SideMenu";

const TestPage = () => {
    return (
        <div className="w-full flex">
            <div className="w-[10%] overflow-y-scroll bg-[#640D5F]">
                <SideMenu />
            </div>
            <div className="w-[90%] p-2">
                <p className="text-sm">Test Page</p>
            </div>
        </div>
    )
}

export default TestPage