import React from "react";
import SideMenu from "./SideMenu";

const AppLayout = ({ children }) => {
    return (
        <div className="w-full flex">
            <div className="w-[10%] h-screen overflow-y-scroll bg-[#640D5F] hidden lg:block z-10">
                <SideMenu />
            </div>
            <div className="w-[90%] px-3">
                {children}
            </div>
        </div>
    );
};

export default AppLayout;