import React from "react";
import congrats_img from "../../../assets/createadvert/congrats_img.png";

const StageFive = () => {
    return (
        <div className="grid grid-cols-12 mt-5">
                <div className="col-span-6">
                    <p className="text-[18px] font-bold mb-2">Advert Preview</p>
                    <div className="border border-[#828282] rounded p-2 h-[25rem] w-[20rem] overflow-y-scroll">
                        <p className="text-[14px] font-bold">Advert</p>
                        <p className="text-[14px] leading-[14px] mt-2">
                            lorem epsum lorem epsum lorem epsumlorem epsum
                        </p>
                        <div className="bg-[#c5c3c3] w-full h-8 rounded mt-5" />
                        <div className="bg-[#c5c3c3] w-full h-8 rounded my-2" />
                        <p className="text-[14px] leading-[14px] mt-5">
                            lorem epsum lorem epsum lorem epsumlorem epsum
                        </p>
                        <img
                            alt="advert"
                            className="w-full h-[10rem] mt-5 rounded"
                            src="https://cdn.pixabay.com/photo/2021/03/12/17/54/coca-cola-6090176_1280.jpg"
                        />
                    </div>
                </div>
                <div className="col-span-6">
                    <div className="w-[30rem] mt-10">
                        <p className="text-[28px] px-10 font-bold text-[#00AB07] text-center">
                            Congratulations! Your advertisement: Vanilla Cookie Sale has been created!
                        </p>
                        <p className="text-[14px] text-[#00C2FF] mt-5 text-center">
                            Continue to 1-Cent Bidding to Bid on your Ad
                        </p>
                        <div className="flex items-center justify-center">
                            <img 
                                alt="congrats" 
                                src={congrats_img} 
                                className="h-[16rem] mx-auto mt-5"
                            />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default StageFive