import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const Settings = () => {
    const navigate = useNavigate();

    const menuList = [
        { id: 1, name: "Edit Account", path: "/account/edit" },
        { id: 1, name: "Delete Account", path: "/account/delete" },
        { id: 1, name: "Privacy Policy", path: "/privacy-policy" },
        { id: 1, name: "Terms and Condition", path: "/terms-and-condition" },
        { id: 1, name: "Cookies Policy", path: "/cookies-policy" },
        { id: 1, name: "Contact Us", path: "/contact-us" },
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Settings</p>
                </div>

                {/* main content */}
                <p className="mt-5 text-center text-[20px] font-bold underline underline-offset-[5px]">
                    Settings
                </p>
                <div className="grid grid-cols-12 gap-14 mt-10 mx-[22rem]">
                    {menuList.map((data) =>(
                        <div className="col-span-4 flex items-center justify-center" key={data.id}>
                            <div
                                onClick={() => navigate(data.path)}
                                className="w-[10rem] h-[9rem] bg-gradient-to-t from-[#101010] 0% to-[#640D5F] 100% flex items-center justify-center rounded cursor-pointer"
                            >
                                <p className="text-[14px] text-[#ffffff]">{data.name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Settings