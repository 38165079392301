import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const YourTimeline = () => {
    const navigate = useNavigate();

    const timelineData = [
        { id: 1, month: "01 OCT 2024", details: "Account Created" },
        { id: 1, month: "10 OCT 2024", details: "Objective reached Hit 10,000 total interactions" },
        { id: 1, month: "26 OCT 2024", details: "Objective reached 10,000 clicks" },
        { id: 1, month: "01 NOV 2024", details: "Objective increase clisk by 10%" },
        { id: 1, month: "13 NOV 2024", details: "Objective increase clisk by 18%" },
    ]

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/objectives")} className="text-[#828282] cursor-pointer">Objectives</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Timeline</p>
                </div>

                {/* main content */}
                <p className="text-[18px] mt-10">
                    Welcome to Your Timeline! Here you can track your
                    progress relative to the Objectives you set for your Advertisements and growth.
                </p>
                <div className="flex justify-end mr-8">
                    <button
                        onClick={() => navigate("/objectives/add")}
                        className="bg-[#0DD315] text-white px-8 py-1 rounded"
                    >
                        Edit
                    </button>
                </div>

                {/* timeline */}
                <div className="relative mt-10">
                    <div className="absolute left-4 top-0 h-full w-1 bg-gray-300 rounded-full" />
                    {timelineData.map((item) => (
                        <div key={item.id} className="flex items-center mb-4 relative">
                            <div className="w-3 h-3 bg-[#00C2FF] rounded-full absolute left-3"></div>
                            <div className="ml-8">
                                <p className="font-bold">{item.month}</p>
                                <p>{item.details}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default YourTimeline