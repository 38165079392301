export const businessSpecificData = [
    {
        id: 1,
        name: "Antons Cookies Shop",
        tier: "2",
        status: "Tier 2 Status",
        interactions: "176,000",
        about: "Hi, we are Anton's cookies shop and we sell our most delicious cookies come and join us to grow our business ...",
        location: "Los Angeles",
        img: "https://cdn.pixabay.com/photo/2019/05/05/18/58/girl-4181395_1280.jpg"
    },
    {
        id: 2,
        name: "Mike’s Baked Goods",
        tier: "3",
        status: "Tier 3 Status",
        interactions: "582,000",
        about: "Hi, we are Mike’s Baked Goods and we sell our goods come and join us to grow our business ...",
        location: "Los Angeles",
        img: "https://cdn.pixabay.com/photo/2017/03/27/14/20/cupcakes-2179039_1280.jpg"
    },
    {
        id: 3,
        name: "Bobs Cookie Shop",
        tier: "2",
        status: "Tier 2 Status",
        interactions: "386,000",
        about: "Hi, we are Bobs Cookie Shop and we sell our most delicious cookies come and join us to grow our business ...",
        location: "Los Angeles",
        img: "https://cdn.pixabay.com/photo/2016/11/29/09/00/doughnuts-1868573_1280.jpg"
    },
    {
        id: 4,
        name: "Alex Coffee Shop",
        tier: "2",
        status: "Tier 2 Status",
        interactions: "985,000",
        about: "Hi, we are Alex Coffee Shop and we sell our most popular coffee come and join us to grow our business ...",
        location: "Los Angeles",
        img: "https://cdn.pixabay.com/photo/2016/11/29/12/54/cafe-1869656_1280.jpg"
    }
];

export const yourLocalAdvertisersData = [
    {
        id: 1,
        name: "Nike Shoe Store",
        status: "Tier 1 Status",
        about: "Hi, we are Nike Shoe Store and we sell our most quality shoes all over the world come and join us to grow our business ...",
        location: "0.5 miles away"
    },
    {
        id: 2,
        name: "Computer Store",
        status: "Tier 4 Status",
        about: "Hi, we are SO11 computer store and we just started our business come and join us to spread our business ...",
        location: "1 mile away"
    },
    {
        id: 3,
        name: "Adidas Store",
        status: "Tier 1 Status",
        about: "Hi, we are Adidas Shoe Store and we sell our most quality shoes all over the world come and join us to grow our business ...",
        location: "0.5 miles away"
    },
    {
        id: 4,
        name: "Puma Store",
        status: "Tier 1 Status",
        about: "Hi, we are Puma Shoe Store and we sell our most quality shoes all over the world come and join us to grow our business ...",
        location: "7 miles away"
    }
];

export const currentTeamUp = [
    {
        id: 1,
        name: "Nike Shoe",
        dateTime: "10th Nov 2024",
        interactions: "1,726,239",
        img: "https://wallpapercave.com/wp/wp10273582.jpg"
    },
    {
        id: 2,
        name: "Adidas",
        dateTime: "17th Nov 2024",
        interactions: "8,715,694 ",
        img: "https://wallpapercave.com/wp/s0lpKWs.jpg"
    },
    {
        id: 3,
        name: "Pepsi",
        dateTime: "21th Dec 2024",
        interactions: "3,895,179 ",
        img: "https://wallpapercave.com/wp/wp2854421.jpg"
    },
    {
        id: 4,
        name: "Puma",
        dateTime: "07th Dec 2024",
        interactions: "5,941,537 ",
        img: "https://wallpapercave.com/wp/8ZfIwgY.jpg"
    }
];

export const PopularTeamUpData = [
    {
        id: 1,
        name: "McDonalds",
        status: "Tier 2 Status",
        about: "Hi, we are McDonalds and we sell our most delicious burgers come and join us to grow our business ...",
        location: "0.5 miles away"
    },
    {
        id: 2,
        name: "KFC",
        status: "Tier 3 Status",
        about: "Hi, we are KFC and we sell our spicy and delicious chicken come and join us to grow our business ...",
        location: "1 mile away"
    },
    {
        id: 3,
        name: "Pizza hut",
        status: "Tier 3 Status",
        about: "Hi, we are Pizza hut and we sell our delicious pizza come and join us to grow our business ...",
        location: "3 miles away"
    },
    {
        id: 4,
        name: "Domino's",
        status: "Tier 1 Status",
        about: "Hi, we are domino's and we sell our delicious pizza come and join us to grow our business ...",
        location: "5 miles away"
    }
];

export const dummyChatData = [
    {
        id: 1,
        name: "Tom",
        message: "Hey Anton! Are we still on for the meeting today?",
        time: "MONDAY 10:00 AM",
        isSender: false
    },
    {
        id: 2,
        name: "Anton",
        message: "Yes, absolutely! Does 2 PM work for you?",
        time: "MONDAY 10:02 AM",
        isSender: true,
        profileImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 3,
        name: "Tom",
        message: "Perfect! I’ll prepare the notes.",
        time: "MONDAY 10:03 AM",
        isSender: false
    },
    {
        id: 4,
        name: "Anton",
        message: "Sounds good. Let me know if you need any specific data.",
        time: "MONDAY 10:05 AM",
        isSender: true,
        profileImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 5,
        name: "Anton",
        message: "Also, did you get a chance to review the latest report?",
        time: "MONDAY 10:10 AM",
        isSender: true,
        profileImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 6,
        name: "Tom",
        message: "Not yet, but it’s on my list for this afternoon. Will update before the meeting.",
        time: "MONDAY 10:11 AM",
        isSender: false
    }
];

export const messageList = [
    {
        id: 1,
        firstName: "John",
        lastName: "Smith",
        postTimeAgo: "20min ago",
        profileImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 2,
        firstName: "Emily",
        lastName: "Johnson",
        postTimeAgo: "4h ago",
        profileImage: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 3,
        firstName: "Michael",
        lastName: "Williams",
        postTimeAgo: "20h ago",
        profileImage: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

    },
    {
        id: 4,
        firstName: "Jessica",
        lastName: "Brown",
        postTimeAgo: "1 days ago",
        profileImage: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 5,
        firstName: "David",
        lastName: "Davis",
        postTimeAgo: "3 days ago",
        profileImage: "https://images.unsplash.com/photo-1474176857210-7287d38d27c6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 6,
        firstName: "Sarah",
        lastName: "Miller",
        postTimeAgo: "3 days ago",
        profileImage: "https://images.unsplash.com/photo-1470441623172-c47235e287ee?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 7,
        firstName: "Ryan",
        lastName: "Taylor",
        postTimeAgo: "5 days ago",
        profileImage: "https://images.unsplash.com/photo-1535467221272-12fb327de525?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 8,
        firstName: "Amanda",
        lastName: "Anderson",
        postTimeAgo: "7 days ago",
        profileImage: "https://images.unsplash.com/photo-1505159401534-f62f81037389?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 9,
        firstName: "Matthew",
        lastName: "Garcia",
        postTimeAgo: "8 days ago",
        profileImage: "https://images.unsplash.com/photo-1551628723-952088378fd3?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        id: 10,
        firstName: "Jennifer",
        lastName: "Martinez",
        postTimeAgo: "10 days ago",
        profileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    }
];

export const oneCentBidData = [
    {
        id: 1,
        name: "Coca Cola",
        image: "https://cdn.pixabay.com/photo/2020/11/26/17/29/coca-cola-5779718_1280.jpg"
    },
    {
        id: 2,
        name: "Chocolate Cake",
        image: "https://cdn.pixabay.com/photo/2017/01/11/11/33/cake-1971552_1280.jpg"
    },
    {
        id: 3,
        name: "Vanilla Ice-Cream",
        image: "https://cdn.pixabay.com/photo/2021/01/18/12/49/ice-cream-5928048_1280.jpg"
    },
    {
        id: 4,
        name: "Pepsi",
        image: "https://cdn.pixabay.com/photo/2020/05/10/05/14/pepsi-5152332_1280.jpg"
    },
    {
        id: 5,
        name: "Restaurant",
        image: "https://cdn.pixabay.com/photo/2016/11/18/22/21/restaurant-1837150_1280.jpg"
    },
    {
        id: 6,
        name: "Cookies",
        image: "https://cdn.pixabay.com/photo/2015/11/19/20/17/cookies-1051884_1280.jpg"
    },
];

export const previousBidtableData = [
    {
        id: 1,
        advertName: "Chocolate Cookies Ad",
        date: "10/12/24",
        adType: "Static",
        adReserve: "$ 300",
        maxBidPrice: "$ 500",
        maxPPI: "$ 2.50",
        invoice: "#3214"
    },
    {
        id: 2,
        advertName: "Vanilla Ice cream Ad",
        date: "10/22/24",
        adType: "Video",
        adReserve: "$ 250",
        maxBidPrice: "$ 1243",
        maxPPI: "$ 1.50",
        invoice: "#2734"
    },
    {
        id: 3,
        advertName: "Banana Ad",
        date: "10/12/24",
        adType: "Static",
        adReserve: "$ 320",
        maxBidPrice: "$ 750",
        maxPPI: "$ 0.01",
        invoice: "#1234"
    },
    {
        id: 4,
        advertName: "Strawberry Ad",
        date: "10/12/24",
        adType: "Pop-Up",
        adReserve: "$ 12.1",
        maxBidPrice: "$ 850",
        maxPPI: "$ 0.50",
        invoice: "#3432"
    }
];

export const yourAdvertData = [
    {
        id: 1,
        name: "Coca Cola",
        created: "07/12/24",
        cost: "$ 79",
        interactions: "188,990",
        image: "https://cdn.pixabay.com/photo/2020/11/26/17/29/coca-cola-5779718_1280.jpg"
    },
    {
        id: 2,
        name: "Chocolate Cake",
        created: "08/12/24",
        cost: "$ 93",
        interactions: "249,542",
        image: "https://cdn.pixabay.com/photo/2017/01/11/11/33/cake-1971552_1280.jpg"
    },
    {
        id: 3,
        name: "Vanilla Ice-Cream",
        created: "09/12/24",
        cost: "$ 55",
        interactions: "123,985",
        image: "https://cdn.pixabay.com/photo/2021/01/18/12/49/ice-cream-5928048_1280.jpg"
    },
    {
        id: 4,
        name: "Pepsi",
        created: "10/12/24",
        cost: "$ 75",
        interactions: "176,000",
        image: "https://cdn.pixabay.com/photo/2020/05/10/05/14/pepsi-5152332_1280.jpg"
    },
    {
        id: 5,
        name: "Restaurant",
        created: "11/12/24",
        cost: "$ 70",
        interactions: "160,578",
        image: "https://cdn.pixabay.com/photo/2016/11/18/22/21/restaurant-1837150_1280.jpg"
    },
    {
        id: 6,
        name: "Cookies",
        created: "12/12/24",
        cost: "$ 100",
        interactions: "300,000",
        image: "https://cdn.pixabay.com/photo/2015/11/19/20/17/cookies-1051884_1280.jpg"
    },
];

export const invoiceData = [
    {
        id: 1,
        advertName: "Chocolate Cookies Ad",
        date: "10/12/24",
        adType: "Static",
        totalSpend: "$ 300",
        adRevenue: "$ 500",
        maxBidPrice: "$ 2.50"
    },
    {
        id: 2,
        advertName: "Vanilla Ice cream Ad",
        date: "10/22/24",
        adType: "Video",
        totalSpend: "$ 250",
        adRevenue: "$ 1243",
        maxBidPrice: "$ 1.50"
    },
    {
        id: 3,
        advertName: "Banana Ad",
        date: "10/12/24",
        adType: "Static",
        totalSpend: "$ 320",
        adRevenue: "$ 750",
        maxBidPrice: "$ 0.01"
    },
    {
        id: 4,
        advertName: "Strawberry Ad",
        date: "10/12/24",
        adType: "Pop-Up",
        totalSpend: "$ 12.1",
        adRevenue: "$ 850",
        maxBidPrice: "$ 0.50"
    }
];

export const activeCollection = [
    {
        id: 1,
        status: "Active",
        maxBidAmount: "300",
        createdDate: "01/12/25",
        updatedlast: "01/13/25",
        advertName: "Chocolate Cookies",
        bidPlaced: "1",
        advertCollectionId: ["#1234", "#2345", "#3456", "#4567"],
        description: "A delicious chocolate cookies advert.",
        duration: "30 days",
    },
    {
        id: 2,
        status: "Active",
        maxBidAmount: "500",
        createdDate: "01/15/25",
        updatedlast: "01/20/25",
        advertName: "Vanilla Ice Cream",
        bidPlaced: "5",
        advertCollectionId: ["#4567", "#5678", "#6789"],
        description: "A refreshing vanilla ice cream advert.",
        duration: "45 days",
    },
    {
        id: 3,
        status: "Inactive",
        maxBidAmount: "200",
        createdDate: "01/20/25",
        updatedlast: "01/29/25",
        advertName: "Strawberry Cake",
        bidPlaced: "3",
        advertCollectionId: ["#7890", "#8901", "#9012", "#1234", "#2345", "#3456", "#4567"],
        description: "A delightful strawberry cake advert.",
        duration: "20 days",
    },
];

export const demoAdvert = [
    {
        id: 1,
        name: "Coca Cola",
        created: "07/12/24",
        image: "https://cdn.pixabay.com/photo/2020/11/26/17/29/coca-cola-5779718_1280.jpg"
    },
    {
        id: 2,
        name: "Chocolate Cake",
        created: "08/12/24",
        image: "https://cdn.pixabay.com/photo/2017/01/11/11/33/cake-1971552_1280.jpg"
    },
    {
        id: 3,
        name: "Vanilla Ice-Cream",
        created: "09/12/24",
        image: "https://cdn.pixabay.com/photo/2021/01/18/12/49/ice-cream-5928048_1280.jpg"
    },
    {
        id: 4,
        name: "Pepsi",
        created: "10/12/24",
        image: "https://cdn.pixabay.com/photo/2020/05/10/05/14/pepsi-5152332_1280.jpg"
    },
    {
        id: 5,
        name: "Restaurant",
        created: "11/12/24",
        image: "https://cdn.pixabay.com/photo/2016/11/18/22/21/restaurant-1837150_1280.jpg"
    },
    {
        id: 6,
        name: "Cookies",
        created: "12/12/24",
        image: "https://cdn.pixabay.com/photo/2015/11/19/20/17/cookies-1051884_1280.jpg"
    },
];