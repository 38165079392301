import React from "react";
import { GoHomeFill } from "react-icons/go";
import { RiAddBoxLine } from "react-icons/ri";
import { activeCollection, demoAdvert } from "../../data";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

const ViewCollection = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    // Find the advert based on the id parameter
    const advert = activeCollection.find(ad => ad.id === parseInt(id));

    return (
        <div className="pt-2 relative">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/one-cent")} className="text-[#828282] cursor-pointer">1 - Cent Bidding</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p onClick={() => navigate("/one-cent/collection")} className="text-[#828282] cursor-pointer">Ad Collection</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">{advert ? advert.advertName : id} Collection</p>
                </div>
                <button
                    className="flex items-center bg-[#28bcd3] hover:bg-[#2d91a0] text-[#ffffff] px-3 py-2 text-[14px] rounded-md"
                >
                    <FaMoneyCheckAlt className="mr-2 text-[22px]" /> Account Balance: $10,000
                </button>
            </div>
            
            {/* Main Content Start */}
            <FaArrowLeftLong
                onClick={() => navigate(-1)}
                className="text-[#1c1c1c] text-[20px] cursor-pointer absolute top-[4.7rem] left-1"
            />

            <p className="text-[24px] font-bold text-[#1c1c1c] mt-5 text-center underline underline-offset-4">
                {advert ? advert.advertName : id} Advert Collection
            </p>

            <div className="grid grid-cols-12 gap-x-10 mt-5">
                <div className="col-span-7">
                    <button
                        className="flex items-center bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] px-3 py-2 text-[14px] rounded-md"
                    >
                        Adverts <RiAddBoxLine className="ml-2 text-[18px]" />
                    </button>
                    <div className="grid grid-cols-12 gap-2 mt-3">
                    {demoAdvert.map((item, index) => (
                        <div key={index} className="col-span-3">
                            <div className="bg-[#ebe7e7] rounded-md p-2">
                                <img
                                    alt="advert"
                                    src={item.image}
                                    className="w-full h-[150px] object-cover rounded-lg"
                                />
                                <div className="flex flex-col mt-2">
                                    <div className="flex items-center justify-between">
                                        <p className="text-gray-600 text-[11px]">Created At: {item.created}</p>
                                        <input
                                            type="checkbox"
                                            checked={index === 1 || index === 5 || index === 2}
                                            className="h-3 w-3 cursor-pointer accent-black text-white"
                                        />
                                    </div>
                                    <p className="text-black text-[14px] mt-1 line-clamp-1">{item.name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className="col-span-5">
                    <div className="flex items-center justify-between gap-x-10">
                        <button
                            className="w-[12rem] px-3 py-2 text-[14px] bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] rounded-md"
                        >
                            View Current Bid
                        </button>

                        <button
                            className="w-[12rem] px-3 py-2 text-[14px] bg-[#1c1c1c] hover:bg-[#000000] text-[#ffffff] rounded-md"
                        >
                            New Bid
                        </button>
                    </div>

                    <div className="mt-4 bg-white shadow-md rounded-lg p-6 border border-gray-300">
                        <p className="text-lg text-gray-800 text-center underline underline-offset-4 mb-4">
                            Bid on Adverts 3/5 Selected
                        </p>
                        <div className="space-y-2">
                            <p className="text-gray-700">Adverts: Cookies Ad, Yummy Ad, Choco Ad</p>
                            <p className="text-gray-700">Max Collection Reserve Amount: $1,200</p>
                            <p className="text-gray-700">Max Bid Price: $0.35</p>
                            <div className="flex items-start gap-x-2">
                                <p className="text-gray-700">Max Spend Per Day:</p>
                                <div className="flex flex-col">
                                    <p className="text-gray-700">Cookies Ad: $100</p>
                                    <p className="text-gray-700">Yummy Ad: $50</p>
                                    <p className="text-gray-700">Choco Ad: $100</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-6">
                        <button
                            className="w-[12rem] px-3 py-2 text-[14px] bg-[#34f034] text-[#ffffff] rounded-md"
                        >
                            Edit Bid
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main Content End */}
        </div>
    )
}

export default ViewCollection;