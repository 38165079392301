import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import bcrypt from "bcryptjs";

const SESSION_KEY = 'userSession';

const getSession = () => {
    const sessionData = sessionStorage.getItem(SESSION_KEY);
    return sessionData ? JSON.parse(sessionData) : null;
};

const isAuthenticated = () => {
    const session = getSession();
    return session && bcrypt.compareSync("admin", session.username);
};

const PrivateRoute = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to="/security-pass" />;
};

export default PrivateRoute;