import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#828282] cursor-pointer" onClick={() => navigate("/settings")}>Settings</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Privacy Policy</p>
                </div>

                {/* main content */}
                <div className="text-gray-700 mt-5">
                    <h1 className="text-3xl font-bold text-gray-800 mb-4">Privacy Policy</h1>
                    <p className="mb-4">
                        Your privacy is important to us. This privacy policy explains how we collect, use, and protect your personal information.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">Information We Collect</h2>
                    <p className="mb-4">
                        We collect information to provide better services to our users. The information we collect includes:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>Personal identification information (Name, email address, phone number, etc.)</li>
                        <li>Usage data (Pages visited, time spent on pages, etc.)</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">How We Use Information</h2>
                    <p className="mb-4">
                        We use the information we collect in various ways, including to:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">Data Protection</h2>
                    <p className="mb-4">
                        We implement a variety of security measures to maintain the safety of your personal information.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">Cookies</h2>
                    <p className="mb-4">
                        We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction.
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">Contact Us</h2>
                    <p className="mb-4">
                        If you have any questions about this privacy policy, you can contact us at:
                    </p>
                    <table className="table-auto w-full mb-4">
                        <thead className="bg-[#cbc6c6]">
                            <tr>
                                <th className="border px-4 py-2">Method</th>
                                <th className="border px-4 py-2">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border px-4 py-2">Email</td>
                                <td className="border px-4 py-2">privacy@example.com</td>
                            </tr>
                            <tr>
                                <td className="border px-4 py-2">Phone</td>
                                <td className="border px-4 py-2">+1 234 567 890</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Footer */}
                <div className="mt-10 border-t pt-6 text-gray-500 text-sm">
                        <p>&copy; 2024 KAS. All rights reserved.</p>
                    </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;