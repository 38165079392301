import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";

const TierIncreaseApplication = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-2 text-sm text-gray-600 font-medium mb-6">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-1 cursor-pointer hover:text-gray-800"
                    >
                        <GoHomeFill className="text-lg" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-lg" />
                    <p
                        onClick={() => navigate("/tier-system")}
                        className="cursor-pointer hover:text-gray-800"
                    >
                        Tier System
                    </p>
                    <MdKeyboardArrowRight className="text-lg" />
                    <p className="text-blue-500 cursor-pointer">Tier Increase Application</p>
                </div>

                {/* main content start */}
                <h1 className="text-center text-2xl font-semibold text-gray-900 mb-8">
                    Tier Increase Application
                </h1>

                <div className="grid grid-cols-12 gap-8">
                    <div className="col-span-8 bg-white shadow-md rounded-lg p-6 border border-gray-300">
                        <h2 className="text-lg font-semibold text-gray-900 mb-4">Personal Details</h2>
                        <form className="space-y-4">
                            {[
                                { label: "Full Name", placeholder: "Enter your full name" },
                                { label: "Email", placeholder: "Enter your email" },
                                { label: "Phone Number", placeholder: "Enter your phone number" },
                                { label: "Account Number", placeholder: "Enter your account number" },
                            ].map((field, index) => (
                                <div key={index} className="flex items-center">
                                    <label className="w-1/3 text-gray-700 font-medium">
                                        {field.label}:
                                    </label>
                                    <input
                                        type="text"
                                        placeholder={field.placeholder}
                                        className="w-2/3 border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                    />
                                </div>
                            ))}

                            <div className="flex items-center">
                                <label className="w-1/3 text-gray-700 font-medium">
                                    Supporting Documents:
                                </label>
                                <div className="w-2/3 flex items-center justify-center border border-gray-300 rounded-md py-2 cursor-pointer hover:bg-gray-100">
                                    <MdOutlineFileUpload className="text-xl text-gray-500" />
                                </div>
                            </div>

                            <div>
                                <label className="block text-gray-700 font-medium mb-2">
                                    Reason for Increase of Tier:
                                </label>
                                <textarea
                                    rows={5}
                                    placeholder="Enter your reason here"
                                    className="w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none"
                                />
                            </div>

                            <div className="text-center mt-6">
                                <button
                                    type="submit"
                                    className="px-6 py-2 bg-green-600 text-white font-medium rounded-md hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:outline-none"
                                >
                                    Apply
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="col-span-4 bg-white shadow-md rounded-lg p-6 border border-gray-300">
                        <h2 className="text-lg font-semibold text-gray-900 mb-4">Business Details</h2>
                        <ul className="space-y-2">
                            {[
                                { label: "Company", value: "Anton’s Cookies" },
                                { label: "Current Tier", value: "2" },
                                { label: "Increase Tier to", value: "Tier 3" },
                                { label: "Current Revenue", value: "$500,000" },
                            ].map((detail, index) => (
                                <li key={index} className="flex justify-between text-gray-700">
                                    <span className="font-medium">{detail.label}:</span>
                                    <span>{detail.value}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* main content end */}
            </div>
        </div>
    );
};

export default TierIncreaseApplication;
