import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { businessSpecificData, currentTeamUp } from "../../data";
import { GoHomeFill } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import MessagePopup from "./MessagePopup";
import { IoClose } from "react-icons/io5";

const ApplicationDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const teamUpDetails = businessSpecificData.find(teamUp => teamUp.id === parseInt(id));
    const [showMessage, setShowMessage] = useState(false);

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Viewing: {teamUpDetails.name}</p>
                </div>

                {/* application */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Viewing: {teamUpDetails.name}
                </p>
                <div className="grid grid-cols-12 gap-5 mt-5">
                    <div className="col-span-8">
                        <div className="flex gap-x-4">
                            <img
                                src={teamUpDetails.img}
                                alt={teamUpDetails.name}
                                className="h-[200px] w-[200px] object-cover rounded-lg"
                            />
                            <div className="flex flex-col gap-y-3">
                                <div className="flex items-center gap-x-2">
                                    <p className="text-[14px] font-bold">Tier Level : </p>
                                    <p className="text-[14px]">{teamUpDetails.tier}</p>
                                </div>
                                <div className="flex items-center gap-x-2">
                                    <p className="text-[14px] font-bold">Location : </p>
                                    <p className="text-[14px]">{teamUpDetails.location}</p>
                                </div>
                                <div className="flex items-center gap-x-2">
                                    <p className="text-[14px] font-bold">Total Interactions : </p>
                                    <p className="text-[14px]">{teamUpDetails.interactions}</p>
                                </div>
                                <p className="text-[14px] bg-[#d9d9d9] p-2 w-[80%] rounded h-[6.3rem] overflow-y-scroll">
                                    {teamUpDetails.about}
                                </p>
                            </div>
                        </div>
                        <div className="mt-14">
                            <p className="text-[16px] font-bold underline underline-offset-[5px]">
                                Sample Adverts
                            </p>
                            <div className="grid grid-cols-12 gap-2 mt-5">
                                {currentTeamUp.map((data) => (
                                    <div className="col-span-3 border border-black p-2 rounded" key={data.id}>
                                        <p className="text-[14px] font-bold mb-2">{data.name}</p>
                                        <img
                                            alt="team-up"
                                            src={data.img}
                                            className="h-[7rem] w-full object-cover rounded"
                                        />
                                        <div className="text-[10px] mt-2 flex items-center">
                                            <p className="font-bold text-[#434343]">Teamed - Up :</p>
                                            <p className="font-normal ml-1">{data.dateTime}</p>
                                        </div>
                                        <div className="text-[10px] flex items-center">
                                            <p className="font-bold text-[#434343]">Total Interactions :</p>
                                            <p className="font-normal ml-1">{data.interactions}</p>
                                        </div>
                                        <button
                                            onClick={() => navigate(`/team-up/current/${data.id}`)}
                                            className="w-full bg-[#00AB07] text-[12px] text-white py-1 rounded mt-2"
                                        >
                                            See Details
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 flex flex-col items-end gap-y-4">

                        {showMessage ?
                            <div className="w-[70%] relative">
                                <p
                                    onClick={() => setShowMessage(!showMessage)}
                                    className="text-center font-bold text-[14px]"
                                >
                                    Messages
                                </p>
                                <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
                                    <IoClose
                                        onClick={() => setShowMessage(!showMessage)}
                                        className="text-[18px] cursor-pointer hover:text-[#f65d5d]"
                                    />
                                </div>
                            </div>
                            :
                            <button
                                onClick={() => setShowMessage(!showMessage)}
                                className="bg-[#575757] text-white px-4 text-[14px] w-[50%] py-[6px] rounded"
                            >
                                Send Message
                            </button>
                        }
                        {!showMessage &&
                            <button
                                onClick={() => navigate(`/team-up/application/${teamUpDetails.id}`)}
                                className="bg-[#575757] text-white px-4 text-[14px] w-[50%] py-[6px] rounded"
                            >
                                Send Team - Up Application
                            </button>
                        }
                        {showMessage &&
                            <MessagePopup />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationDetails