import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";

import home from "../assets/sidemenuimg/house.png";
import settings from "../assets/sidemenuimg/settings.png";
import team_up from "../assets/sidemenuimg/team_up.png";
import your_bidding from "../assets/sidemenuimg/your_bidding.png";
import financials from "../assets/sidemenuimg/financials.png";
import objectives from "../assets/sidemenuimg/objectives.png";
import performance from "../assets/sidemenuimg/performance.png";
import tier_system from "../assets/sidemenuimg/tier_system.png";
import cent_bidding from "../assets/sidemenuimg/1_cent_bidding.png";


import team from "../assets/sidemenuimg/team.png";
import teamup_search from "../assets/sidemenuimg/teamup-search.png";
import popular_teamup from "../assets/sidemenuimg/popular-teamup.png";
import teamup_message from "../assets/sidemenuimg/teamup-messages.png";
import teamup_application from "../assets/sidemenuimg/teamup-application.png";
import teamup_current from "../assets/sidemenuimg/teamup-current.png";
import teamup_filter from "../assets/sidemenuimg/teamup-filter.png";
import teamup_profile from "../assets/sidemenuimg/teamup-profile.png";
import previous_bid from "../assets/sidemenuimg/previous_bid.png";
import how_it_work from "../assets/sidemenuimg/how_it_work.png";

import kv from "../assets/sidemenuimg/kv.png";
import advert_collection from "../assets/sidemenuimg/advert_collection.png";
import your_advert from "../assets/sidemenuimg/your_advert.png";
import active_advert from "../assets/sidemenuimg/active_advert.png";
import draft_advert from "../assets/sidemenuimg/draft_advert.png";
import quickcreate_advert from "../assets/sidemenuimg/quickcreate_advert.png";
import advert_create from "../assets/sidemenuimg/advert_create.png";
import inactive_advert from "../assets/sidemenuimg/inactive_advert.png";

import higher_tier from "../assets/sidemenuimg/higher_tier.png";
import your_tier from "../assets/sidemenuimg/your_tier.png";
import tier_application from "../assets/sidemenuimg/tier_application.png";

import your_settings from "../assets/sidemenuimg/your_settings.png";
import keyword from "../assets/sidemenuimg/keyword.png";
import account from "../assets/sidemenuimg/account.png";
import edit_account from "../assets/sidemenuimg/edit_account.png";
import delete_account from "../assets/sidemenuimg/delete_account.png";
import trending_advert from "../assets/sidemenuimg/trending_advert.png";

import billing_invoice from "../assets/sidemenuimg/billing_invoice.png";
import privacy_policy from "../assets/sidemenuimg/privacy_policy.png";
import cookies_policy from "../assets/sidemenuimg/cookies_policy.png";
import terms_and_conditions from "../assets/sidemenuimg/terms_and_conditions.png";
import contact from "../assets/sidemenuimg/contact.png";
import contact_expert from "../assets/sidemenuimg/contact_expert.png";

const SideMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [activeSubItem, setActiveSubItem] = useState(null);

    const menuItems = [
        { icon: home, name: "Home", path: "/" },
        {
            icon: your_bidding,
            name: "Bidding",
            subItems: [
                { icon: cent_bidding, name: "1 Cent Bidding", path: "/one-cent" },
                { icon: advert_collection, name: "Add Collections", path: "/one-cent/collection" },
                { icon: previous_bid, name: "Previous Bid", path: "/one-cent/previous-bid" },
                { icon: how_it_work, name: "How 1 Cent Bidding Works", path: "/one-cent/how-bid-work" }
            ]
        },
        {
            icon: your_advert,
            name: "Your Adverts",
            subItems: [
                { icon: advert_create, name: "Create Adverts", path: "/create-advert" },
                { icon: active_advert, name: "Active Adverts", path: "/your-advert" },
                { icon: kv, name: "KV Adverts", path: "/kv-advert" },
                { icon: inactive_advert, name: "Inactive Adverts", path: "/your-advert/inactive" },
                { icon: draft_advert, name: "Drafted Adverts", path: "/your-advert/draft" },
                { icon: quickcreate_advert, name: "Quick Create", path: "/quick-create" }
            ]
        },
        {
            icon: tier_system,
            name: "Tier System",
            subItems: [
                { icon: your_tier, name: "Your Tier", path: "/tier-system" },
                { icon: higher_tier, name: "Higher Tier Advertisers", path: "/tier-system/higher-advertiser" },
                { icon: tier_application, name: "Tier Increase Application", path: "/tier-system/increase-application" },
                { icon: how_it_work, name: "How Tier System Works", path: "/tier-system/how-it-work" }
            ]
        },
        {
            icon: team,
            name: "Your Team Up",
            subItems: [
                { icon: team_up, name: "Team Up", path: "/team-up" },
                { icon: teamup_search, name: "Team Up Search", path: "/team-up/search" },
                { icon: teamup_current, name: "Your Current Team Ups", path: "/team-up/current" },
                { icon: popular_teamup, name: "Popular Team Up Advertisers", path: "/team-up/popular" },
                { icon: teamup_filter, name: "Team Up Filters", path: "/team-up/filters" },
                { icon: teamup_application, name: "Team Up Application", path: "/team-up/application" },
                { icon: teamup_profile, name: "Team Up Profile", path: "/team-up/profile" },
                { icon: teamup_message, name: "Team Up Messages", path: "/team-up/message" },
                { icon: how_it_work, name: "How Team Up Works", path: "/team-up/how-it-work" }
            ]
        },
        { icon: performance, name: "Performance", path: "/performance" },
        { icon: financials, name: "Financials", path: "/financial" },
        { icon: objectives, name: "Objectives", path: "/objectives" },
        {
            icon: settings,
            name: "Settings",
            subItems: [
                { icon: your_settings, name: "Your Settings", path: "/settings" },
                { icon: keyword, name: "Your Keywords", path: "/keywords" },
                { icon: account, name: "Your Account", path: "/account" },
                { icon: edit_account, name: "Edit Account", path: "/account/edit" },
                { icon: delete_account, name: "Delete Account", path: "/account/delete" },
                { icon: trending_advert, name: "Trending Adverts", path: "/trending-adverts" },
                { icon: billing_invoice, name: "Billing and Invoice", path: "/billing-invoice" },
                { icon: privacy_policy, name: "Privacy Policy", path: "/privacy-policy" },
                { icon: cookies_policy, name: "Cookies Policy", path: "/cookies-policy" },
                { icon: terms_and_conditions, name: "Terms And Conditions", path: "/terms-and-condition" },
                { icon: contact, name: "Contact Us", path: "/contact-us" },
                { icon: contact_expert, name: "Contact Expert", path: "/contact-expert" },
                { icon: how_it_work, name: "How It Works", path: "/how" },
            ]
        }
    ];

    useEffect(() => {
        const path = location.pathname;
        const activeMenuItem = menuItems.find(item => item.path === path);
        if (activeMenuItem) {
            setActiveItem(activeMenuItem.name);
            setActiveSubItem(null);
            setOpenDropdownIndex(null);
        } else {
            menuItems.forEach((item, index) => {
                if (item.subItems) {
                    const activeSubMenuItem = item.subItems.find(subItem => subItem.path === path);
                    if (activeSubMenuItem) {
                        setActiveItem(item.name);
                        setActiveSubItem(activeSubMenuItem.name);
                        setOpenDropdownIndex(index);
                    }
                }
            });
        }
    }, [location.pathname]);

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const handleItemClick = (path, name) => {
        setActiveItem(name);
        setActiveSubItem(null);
        setOpenDropdownIndex(null);
        navigate(path);
    };

    const handleSubItemClick = (path, name) => {
        setActiveItem(null);
        setActiveSubItem(name);
        navigate(path);
    };

    return (
        <div className="flex flex-col items-center mt-3 ml-5">
            <ul>
                {menuItems.map((item, index) => (
                    item.subItems ? (
                        <li key={index} className="flex flex-col">
                            <div className="flex items-center gap-x-1">
                                <div
                                    className={`h-[6rem] w-[6rem] p-5 flex flex-col items-center justify-center rounded-full bg-[#ffffff] hover:bg-gray-200 mb-3 cursor-pointer`}
                                    onClick={() => toggleDropdown(index)}
                                >
                                    <img
                                        src={item.icon}
                                        alt={item.name}
                                        className="h-[2.5rem] w-[2.5rem] mb-1"
                                    />
                                    <p className="text-[12px] text-[#000000] font-semibold leading-[12px] text-center">
                                        {item.name}
                                    </p>

                                </div>
                                <motion.div
                                    className="ml-auto cursor-pointer"
                                    onClick={() => toggleDropdown(index)}
                                    animate={{ rotate: openDropdownIndex === index ? 180 : 0 }}
                                >
                                    <IoIosArrowDown className="text-[18px] text-white" />
                                </motion.div>
                            </div>
                            <AnimatePresence>
                                {openDropdownIndex === index && (
                                    <motion.ul
                                        initial={{ height: 0, opacity: 0 }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className="pl-0 mb-1"
                                    >
                                        {item.subItems.map((subItem, subIndex) => (
                                            <li
                                                key={subIndex}
                                                onClick={() => handleSubItemClick(subItem.path, subItem.name)}
                                                className={`h-[5rem] w-[5rem] p-2 ml-2 flex flex-col items-center justify-center rounded-sm bg-[#ffffff] mb-2 cursor-pointer 
                                                    ${activeSubItem === subItem.name ? "border-[2px] border-[#00AB07] text-black" : "hover:bg-gray-200"}`}
                                            >
                                                <img
                                                    src={subItem.icon}
                                                    alt={subItem.name}
                                                    className={`h-[2rem] w-[2rem] mb-1`}
                                                />
                                                <p className={`text-[10.5px] font-semibold leading-[12px] text-center ${activeSubItem === subItem.name ? "text-black" : "text-black"}`}>
                                                    {subItem.name}
                                                </p>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </li>
                    ) : (
                        <li
                            key={index}
                            onClick={() => handleItemClick(item.path, item.name)}
                            className={`h-[6rem] w-[6rem] p-5 flex flex-col items-center justify-center rounded-full bg-[#ffffff] mb-3 cursor-pointer 
                                ${activeItem === item.name ? "border-[2px] border-[#00AB07]" : "hover:bg-gray-200"}`}
                        >
                            <img
                                src={item.icon}
                                alt={item.name}
                                className={`h-[2.5rem] w-[2.5rem] mb-1`}
                            />
                            <p className={`text-[12px] text-[#000000] font-semibold leading-[12px] text-center ${activeItem === item.name ? "text-black" : "text-black"}`}>
                                {item.name}
                            </p>
                        </li>
                    )
                ))}
            </ul>
        </div>
    );
};

export default SideMenu;