import React from "react";
import { motion } from "framer-motion";
import { oneCentBidData } from "../data";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const YourAdvert = () => {
    const navigate = useNavigate();

    const animationSettings = {
        initial: { opacity: 0, y: -20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.1,
                ease: "easeOut",
            },
        },
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/your-advert")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Your Adverts
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Active Adverts</p>
                </div>

                {/* main content */}
                <motion.div
                    {...animationSettings}
                    className="flex justify-between mt-5"
                >
                    <p className="text-[24px] font-bold text-[#333]">
                        Active Ads On Display
                    </p>
                    <div className="flex flex-col bg-[#f5f5f5] shadow-md p-4 rounded-lg">
                        <p className="font-bold text-[16px] mb-2 text-[#555]">Business: Anton’s Cookies</p>
                        <p className="font-bold text-[16px] text-[#555]">Tier: Tier 3</p>
                    </div>
                </motion.div>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="grid grid-cols-12 gap-6 mt-10"
                >
                    {oneCentBidData.map((data) => (
                        <motion.div
                            key={data.id}
                            variants={cardVariants}
                            className="col-span-3 border border-gray-300 bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer transform transition-transform hover:scale-105"
                            onClick={() => navigate(`/your-advert/post-view/${data.id}`)}
                        >
                            <p className="text-center bg-gray-400 text-[16px] font-semibold py-2">
                                {data.name} Ad
                            </p>
                            <div className="overflow-hidden">
                                <img
                                    alt="advert"
                                    src={data.image}
                                    className="w-full h-[12rem] object-cover transform transition-transform duration-300 hover:scale-110"
                                />
                            </div>
                            <div className="p-4">
                                <div className="flex items-center justify-between mb-2">
                                    <p className="text-[14px] text-[#555]">CP3V:</p>
                                    <p className="text-[14px] text-[#555]">Tier: 1</p>
                                </div>
                                <p className="text-[14px] text-[#555] mb-1">CP3C:</p>
                                <p className="text-[14px] text-[#555] mb-1">CP3000 Impressions:</p>
                                <p className="text-[14px] text-[#555]">Expenditure:</p>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    )
}

export default YourAdvert