import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import FilterSearch from "./teamup/FilterSearch";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoSearchSharp, IoClose } from "react-icons/io5";
import { businessSpecificData, yourLocalAdvertisersData } from "../data";

const TeamUp = () => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Team - Up</p>
                </div>

                <div className="flex items-start justify-between mt-5">
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <p className="text-[24px] font-bold">Team - Up</p>
                            <p
                                onClick={() => navigate("/team-up/current")}
                                className="text-[12px] text-[#ffffff] ml-[6rem] bg-[#640D5F] px-3 py-1 rounded-md cursor-pointer"
                            >
                                Your Current Team-Up Adverts
                            </p>
                        </div>
                        <div className="relative mt-3">
                            <input
                                type="text"
                                placeholder="Search for Team-Up"
                                value={inputValue}
                                onClick={() => navigate("/team-up/search")}
                                onChange={(e) => setInputValue(e.target.value)}
                                className="bg-[#ffffff] pl-2 pr-8 py-2 w-full border border-black text-[13px] rounded focus:outline-none"
                            />
                            <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                                {inputValue.length > 1 ? (
                                    <IoClose
                                        className="text-[20px] text-[#000000] hover:text-[#f65d5d] cursor-pointer"
                                        onClick={() => setInputValue("")}
                                    />
                                ) : (
                                    <IoSearchSharp className="text-[20px] text-[#000000] cursor-pointer" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-0">
                        <FilterSearch />
                    </div>
                </div>

                <div className="mt-5">
                    <p className="px-4 py-[5px] bg-[#D9D9D9] rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit">
                        Business Specific Advertisers
                    </p>

                    <div className="grid grid-cols-12 gap-5 mt-3">
                        {businessSpecificData.map((data) => (
                            <div className="col-span-3 border border-black" key={data.id}>
                                <p className="text-[18px] font-bold border-b border-black p-3">
                                    {data.name}
                                </p>
                                <div className="p-3">
                                    <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                    <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                    <div className="bg-[#D9D9D9] p-3 overflow-y-scroll h-[8rem] mt-1">
                                        <p className="text-[14px]">
                                            {data.about}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-4 mt-2">
                                        <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                        <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center mt-2 mb-8">
                                    <p
                                        onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                        className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] px-8 py-2 rounded-md cursor-pointer"
                                    >
                                        Team - UP
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-10">
                    <p className="px-4 py-[5px] bg-[#D9D9D9] rounded-md whitespace-nowrap cursor-pointer font-bold text-[13px] w-fit">
                        Your Local Advertisers
                    </p>

                    <div className="grid grid-cols-12 gap-5 mt-3">
                        {yourLocalAdvertisersData.map((data) => (
                            <div className="col-span-3 border border-black" key={data.id}>
                                <p className="text-[18px] font-bold border-b border-black p-3">
                                    {data.name}
                                </p>
                                <div className="p-3">
                                    <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                    <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                    <div className="bg-[#D9D9D9] p-3 overflow-y-scroll h-[8rem] mt-1">
                                        <p className="text-[14px]">
                                            {data.about}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-4 mt-2">
                                        <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                        <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center mt-2 mb-8">
                                    <p
                                        onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                        className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] px-8 py-2 rounded-md cursor-pointer"
                                    >
                                        Team - UP
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamUp