import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdFilterAlt, MdKeyboardArrowRight } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import { motion } from "framer-motion";
import { yourLocalAdvertisersData } from "../../data";

const HigherTierAdvertiser = () => {
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);

    const animationSettings = {
        initial: { opacity: 0, y: -20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
    };

    const filterOptions = [
        { label: "Tier 4", value: "tier4" },
        { label: "Tier 3", value: "tier3" },
        { label: "Tier 2", value: "tier2" },
        { label: "Tier 1", value: "tier1" },
    ];

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/tier-system")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Tier System
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Higher Tier Advertisers Team - Up</p>
                </div>

                {/* main content */}
                <motion.div
                    {...animationSettings}
                    className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]"
                >
                    Higher Tier Advertisers Team - Up
                </motion.div>

                <div className="grid grid-cols-12 mt-10">
                    <div className="col-span-4">
                        <motion.div
                            {...animationSettings}
                            className="flex w-fit items-center gap-x-3 bg-[#F0F0F0] p-3 rounded-md shadow-md"
                        >
                            <p className="text-[16px] font-bold">Your Tier :</p>
                            <p className="text-[14px] flex items-center bg-[#E0E0E0] text-[#626262] px-3 py-1 rounded-md">Tier 3</p>
                        </motion.div>
                        <motion.div
                            {...animationSettings}
                            className="flex flex-col mt-5 bg-[#FAFAFA] p-4 rounded-md shadow-md"
                        >
                            <p className="text-[16px] font-bold">Perks :</p>
                            <p className="text-[14px] text-[#626262] mt-2">
                                The perks of Tier 3 include being third in the rolling advertisements.
                                You also bid against other advertisers in Tier 3 with similar revenues.
                            </p>
                        </motion.div>
                    </div>
                    <div className="col-span-4">
                        <motion.div
                            {...animationSettings}
                            className="relative"
                        >
                            <input
                                type="text"
                                placeholder="Enter the name of the advertiser"
                                className="w-full pl-8 pr-2 py-2 border border-[#b1b1b1] text-[14px] rounded-full focus:outline-none focus:ring-2 focus:ring-[#00AB07]"
                            />
                            <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
                                <IoSearchSharp
                                    className="text-[20px] text-[#000000] hover:text-[#00AB07] cursor-pointer"
                                />
                            </div>
                        </motion.div>
                    </div>
                    <div className="col-span-4 flex justify-end">
                        <motion.div
                            {...animationSettings}
                            className="relative"
                        >
                            <div
                                onClick={() => setShowFilter(!showFilter)}
                                className="flex items-center justify-center gap-x-2 bg-black w-[8rem] py-2 cursor-pointer "
                            >
                                <p className="text-[14px] text-[#ffffff]">
                                    Filter Search
                                </p>
                                <MdFilterAlt className="text-[18px] text-[#f68c36]" />
                            </div>

                            {showFilter &&
                                <motion.div
                                    initial={{ opacity: 0, y: -8 }}
                                    animate={{ opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } }}
                                    className="absolute right-0 w-full bg-[#D9D9D9] border border-black p-2 z-10"
                                >
                                    {filterOptions.map((option, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0, transition: { duration: 0.3, ease: "easeOut" } }}
                                            className="flex items-center gap-x-3 mb-1"
                                        >
                                            <input
                                                type="checkbox"
                                                className="h-[14px] w-[14px] accent-[#00AB07] cursor-pointer"
                                            />
                                            <p className="text-[12px]">{option.label}</p>
                                        </motion.div>
                                    ))}
                                </motion.div>
                            }
                        </motion.div>
                    </div>
                </div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden: { opacity: 0 },
                        visible: {
                            opacity: 1,
                            transition: {
                                staggerChildren: 0.2,
                                delayChildren: 0.1,
                                ease: "easeOut",
                            },
                        },
                    }}
                    className="grid grid-cols-12 gap-5 mt-10"
                >
                    {yourLocalAdvertisersData.map((data) => (
                        <motion.div
                            key={data.id}
                            initial="hidden"
                            animate="visible"
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0, transition: { duration: 0.3, ease: "easeOut" } },
                            }}
                            whileHover={{ y: -15 }}
                            className="col-span-3 border border-gray-300 bg-white shadow-lg rounded-lg overflow-hidden"
                        >
                            <p className="text-[18px] font-bold border-b border-gray-300 p-3 bg-gray-100">
                                {data.name}
                            </p>
                            <div className="p-4">
                                <p className="text-[14px] font-bold text-[#626262]">{data.status}</p>
                                <p className="text-[14px] font-bold text-[#626262] mt-2">About :</p>
                                <div className="bg-gray-100 p-3 overflow-y-scroll h-[8rem] mt-1 rounded-md">
                                    <p className="text-[14px]">
                                        {data.about}
                                    </p>
                                </div>
                                <div className="flex items-center gap-x-4 mt-2">
                                    <p className="text-[14px] font-bold text-[#626262]">Location</p>
                                    <p className="text-[14px] font-normal text-[#626262]">{data.location}</p>
                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-2 mb-8">
                                <p
                                    onClick={() => navigate(`/team-up/application/view/${data.id}`)}
                                    className="text-[14px] text-[#ffffff] font-bold bg-[#00AB07] hover:bg-[#00AB20] px-8 py-2 rounded-md cursor-pointer"
                                >
                                    Team - UP
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    )
}

export default HigherTierAdvertiser;