import React, { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { motion } from "framer-motion";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { yourAdvertData } from "../../data";
import { IoIosCloseCircle } from "react-icons/io";

const DraftedAdvert = () => {
    const navigate = useNavigate();
    const [advertType, setAdvertType] = useState("");

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.1,
                ease: "easeOut",
            },
        },
    };

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/your-advert")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Your Adverts
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Drafted Adverts</p>
                </div>

                {/* main content */}
                <div className="grid grid-cols-12">
                    <div className="col-span-4" />
                    <p className="col-span-4 text-[20px] font-bold text-center mt-5 underline underline-offset-[5px]">
                        Drafted Adverts
                    </p>
                    <div className="col-span-4 flex items-center justify-end gap-x-3">
                        <p className="text-[#626262] text-[14px] font-bold">Advert Type :</p>
                        <div className="relative">
                            <select
                                required
                                value={advertType}
                                onChange={(e) => setAdvertType(e.target.value)}
                                className="w-[10rem] bg-[#000000] px-3 py-[6px] text-white text-[14px] border border-[#ffffff] focus:outline-none appearance-none rounded"
                            >
                                <option value={""}>Select</option>
                                <option value="Stacic Advert">Stacic Advert</option>
                                <option value="Video Advert">Video Advert</option>
                                <option value="Pop-Up Advert">Pop-Up Advert</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-4 w-4 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="mt-10">
                    <p className="text-[20px] font-bold underline underline-offset-[5px]">
                        Showing : Static Kind Viewer Advert Drafts
                    </p>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={containerVariants}
                        className="grid grid-cols-10 gap-5 mt-10"
                    >
                        {yourAdvertData.map((data) => (
                            <motion.div
                                key={data.id}
                                variants={cardVariants}
                                className="col-span-2 p-3 border border-gray-300 bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105"
                            >
                                <div className="flex items-center justify-between bg-gray-400 rounded-lg px-2 py-1 mb-2">
                                    <p className="text-[14px] text-[#000000]">
                                        Created : {data.created}
                                    </p>
                                    <IoIosCloseCircle
                                        className="text-[20px] text-[#000000] hover:text-blue-gray-900 cursor-pointer"
                                    />
                                </div>
                                <div className="overflow-hidden">
                                    <img
                                        alt="advert"
                                        src={data.image}
                                        className="w-full h-[10rem] overflow-hidden object-cover transform transition-transform duration-300 hover:scale-110 cursor-pointer rounded-lg"
                                    />
                                </div>
                                <p className="p-2 mt-2 bg-[#d9d9d9] text-center text-[14px] font-bold line-clamp-1 rounded">
                                    {data.name} Advert
                                </p>
                                <div className="flex items-center justify-between mt-2">
                                    <button className="text-[12px] w-[6rem] text-[#ffffff] rounded py-2 bg-[#00AB07] hover:opacity-90">
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => navigate(`/your-advert/post-view/${data.id}`)}
                                        className="text-[12px] w-[6rem] text-[#ffffff] rounded py-2 bg-[#640D5F] hover:opacity-90"
                                    >
                                        Bid and Post
                                    </button>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default DraftedAdvert;