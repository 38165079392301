import React from "react";
import { currentTeamUp } from "../../data";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const YourTeamUpProfile = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Your Team - Up Profile</p>
                </div>

                {/* Your Team - Up Profile */}
                <p className="text-center text-[22px] font-bold mt-4 underline underline-offset-[5px]">
                    Your Team - Up Profile
                </p>
                <div className="grid grid-cols-12 gap-5 mt-10">
                    <div className="col-span-6">
                        <div className="flex items-start justify-between gap-x-5">
                            <img
                                alt="team-up"
                                className="h-[13rem] w-[13rem] rounded object-cover"
                                src="https://images.unsplash.com/photo-1531891437562-4301cf35b7e4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8"
                            />
                            <div className="flex flex-col justify-between h-[13rem]">
                                <button className="w-[10rem] bg-[#d9d9d9] text-[14px] py-4 font-bold rounded">
                                    Share Profile
                                </button>
                                <button className="w-[10rem] bg-[#d9d9d9] text-[14px] py-4 font-bold rounded">
                                    Edit Profile
                                </button>
                                <button className="w-[10rem] bg-[#d9d9d9] text-[14px] py-4 font-bold rounded">
                                    Change Profile
                                </button>
                            </div>
                            <div className="border border-black p-2 rounded w-[15rem]">
                                <p className="text-start text-[14px] pb-2 font-bold">
                                    Display on Profile
                                </p>
                                <div className="flex items-center gap-x-3 pb-[4px]">
                                    <input
                                        type="checkbox"
                                        checked
                                        className="w-3 h-3 accent-[#00AB07] cursor-pointer"
                                    />
                                    <p className="text-[13px]">Tier Level</p>
                                </div>
                                <div className="flex items-center gap-x-3 pb-[4px]">
                                    <input
                                        type="checkbox"
                                        checked
                                        className="w-3 h-3 accent-[#00AB07] cursor-pointer"
                                    />
                                    <p className="text-[13px]">Business Location</p>
                                </div>
                                <div className="flex items-center gap-x-3 pb-[4px]">
                                    <input
                                        type="checkbox"
                                        checked
                                        className="w-3 h-3 accent-[#00AB07] cursor-pointer"
                                    />
                                    <p className="text-[13px]">Business Description</p>
                                </div>
                                <div className="flex items-center gap-x-3 pb-[4px]">
                                    <input
                                        type="checkbox"
                                        className="w-3 h-3 accent-[#00AB07] cursor-pointer"
                                    />
                                    <p className="text-[13px]">Sample Adverts</p>
                                </div>
                                <div className="flex items-center gap-x-3 pb-[4px]">
                                    <input
                                        type="checkbox"
                                        className="w-3 h-3 accent-[#00AB07] cursor-pointer"
                                    />
                                    <p className="text-[13px]">Previous Partnerships</p>
                                </div>
                                <div className="flex items-center gap-x-3 pb-[4px]">
                                    <input
                                        type="checkbox"
                                        checked
                                        className="w-3 h-3 accent-[#00AB07] cursor-pointer"
                                    />
                                    <p className="text-[13px]">Total Interactions</p>
                                </div>
                                <div className="flex items-center gap-x-3">
                                    <input
                                        type="checkbox"
                                        className="w-3 h-3 accent-[#00AB07] cursor-pointer"
                                    />
                                    <p className="text-[13px]">Total Revenue</p>
                                </div>
                            </div>
                        </div>

                        <textarea
                            rows={7}
                            placeholder="Detailed Description of Business here"
                            className="mt-5 bg-[#d9d9d9] p-2 rounded resize-none w-full focus:outline-none"
                        />
                    </div>
                    <div className="col-span-6">
                        <p className="text-[14px] font-bold text-center bg-[#d9d9d9] px-3 py-1 rounded">
                            Your Adverts on Display
                        </p>
                        <div className="mt-2 text-[14px]">
                            {currentTeamUp.map((data) => (
                                <p key={data.id}>
                                    {data.id}. {data.name}
                                </p>
                            ))}
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-4">
                            {currentTeamUp.map((data) => (
                                <div className="col-span-3 border border-black p-2 rounded" key={data.id}>
                                    <p className="text-[14px] font-bold mb-2">{data.name}</p>
                                    <img
                                        alt="team-up"
                                        src={data.img}
                                        className="h-[5rem] w-full object-cover rounded"
                                    />
                                    <div className="text-[10px] mt-2 flex items-center">
                                        <p className="font-bold text-[#434343]">Teamed - Up :</p>
                                        <p className="font-normal ml-1">{data.dateTime}</p>
                                    </div>
                                    <div className="text-[10px] flex items-center">
                                        <p className="font-bold text-[#434343]">Total Interactions :</p>
                                        <p className="font-normal ml-1">{data.interactions}</p>
                                    </div>
                                    <button
                                        onClick={() => navigate(`/team-up/current/${data.id}`)}
                                        className="w-full bg-[#00AB07] text-[12px] text-white py-1 rounded mt-2"
                                    >
                                        See Details
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YourTeamUpProfile