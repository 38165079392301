import React from "react";
import { currentTeamUp } from "../../data";
import { GoHomeFill } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

const CurrentTeamUpDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const teamUpDetails = currentTeamUp.find(teamUp => teamUp.id === parseInt(id));

    if (!teamUpDetails) {
        return <div>Team-Up details not found</div>;
    }

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-12 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p
                        onClick={() => navigate("/team-up/current")}
                        className="text-[#828282] cursor-pointer"
                    >
                        Your Current Team - Up
                    </p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">{teamUpDetails.name} Team - Up Details</p>
                </div>

                {/* Your Current Team - Up */}
                <div className="mt-8">
                    <h1 className="text-2xl font-bold text-center underline underline-offset-[5px]">
                        {teamUpDetails.name} Team - Up Details
                    </h1>
                    <div className="grid grid-cols-12 gap-14 mt-10">
                        <div className="col-span-4">
                            <p className="text-[14px] font-bold mb-2">
                                Teamed - Up with on : {teamUpDetails.dateTime}
                            </p>
                            <p className="text-[14px] px-5 py-1 text-[#ffffff] bg-[#00AB07] rounded w-fit cursor-pointer">
                                Send {teamUpDetails.name} a Message
                            </p>
                            <img
                                src={teamUpDetails.img}
                                alt={teamUpDetails.name}
                                className="h-[20rem] w-full rounded object-cover mt-5"
                            />
                            <div className="flex flex-col gap-y-2 mt-5 text-[14px]">
                                <p>CP3C :</p>
                                <p>CP3000 Impressions :</p>
                                <p>CP3V :</p>
                            </div>
                        </div>
                        <div className="col-span-8 flex gap-x-14 mt-12">
                            <div className="mt-[30px]">
                                <p className="text-[14px] bg-[#d9d9d9] px-3 py-1 w-fit rounded font-bold">
                                    Cost Analysis
                                </p>
                                <div className="flex items-center gap-x-3 mt-2">
                                    <p className="text-[14px]">{teamUpDetails.name} pays :</p>
                                    <p className="text-[14px] font-bold">$100</p>
                                </div>
                                <div className="flex items-center gap-x-3 mt-1">
                                    <p className="text-[14px]">You pay :</p>
                                    <p className="text-[14px] font-bold">$20</p>
                                </div>
                                <div className="flex items-center gap-x-3 mt-1">
                                    <p className="text-[14px]">Total Paid :</p>
                                    <p className="text-[14px] font-bold">$120</p>
                                </div>
                            </div>
                            <div className="mt-[30px]">
                                <p className="text-[14px] bg-[#d9d9d9] px-3 py-1 w-fit rounded font-bold">
                                    Interaction Analysis
                                </p>
                                <div className="flex items-center gap-x-3 mt-2">
                                    <p className="text-[14px]"># of Views : </p>
                                    <p className="text-[14px] font-bold">$100</p>
                                </div>
                                <div className="flex items-center gap-x-3 mt-1">
                                    <p className="text-[14px]"># of Clicks : </p>
                                    <p className="text-[14px] font-bold">$120</p>
                                </div>
                                <div className="flex items-center gap-x-3 mt-1">
                                    <p className="text-[14px]"># of Interactions : </p>
                                    <p className="text-[14px] font-bold">$130</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentTeamUpDetails